import { BasketLineData } from '@dominos/business/functions/basket'
import {
  getDefaultProductPrice as defaultPrice,
  mapProductContextToPriceProductForNonPortionProducts,
  mapProductContextToPriceProductForPortionProducts,
  getMenuProductPrice as menuPrice,
} from '@dominos/business/functions/pricing'
import { getFormattedPrice } from '@dominos/business/functions/text'
import { ProductData, useDynamicPricing, useProductPricing } from '@dominos/hooks-and-hocs'
import { useEffect, useRef } from 'react'
import { mapBasketLineDataToHalfNHalfPriceProduct, mapBasketLineDataToPriceProduct } from './map-product'

export const usePricing = (): ProductPricing & {
  getPrice: (product: BasketLineData, halfLeft?: BasketLineData, halfRight?: BasketLineData) => void
} & {
  getPriceForDimensionalProduct: (
    productData: ProductData,
    basketLineData: BasketLineData,
    selectedDimensionSet: Bff.Dimensions.DimensionSet,
    portions: Portion[] | undefined,
  ) => void
} & {
  resetPrice: () => void
} => {
  const [productPricing, setProductPricing] = useProductPricing()
  const fallbackPrice = useRef<ProductPricing>(productPricing)
  const { shouldFetchPrice, fetchPrice, dynamicPricing, error } = useDynamicPricing()
  const getPriceForDimensionalProduct = (
    productData: ProductData,
    basketLineData: BasketLineData,
    selectedDimensionSet: Bff.Dimensions.DimensionSet,
    portions: Portion[] | undefined,
  ) => {
    if (!productData.code) return

    const shouldUseCachedPrice =
      productData.isSimpleProduct || (productData.isRecipeProduct && !basketLineData.sauce && !basketLineData.toppings)

    if (shouldUseCachedPrice) {
      const priceData = productData.getPrices(selectedDimensionSet)
      setProductPricing({
        formattedPrice: priceData?.price ? getFormattedPrice(priceData?.price) : null,
        formattedPromoPrice: priceData?.promoPrice ? getFormattedPrice(priceData?.promoPrice) : null,
      })

      return
    }

    let priceProduct: Bff.PriceProduct.PriceProduct | null = null

    if (productData.isRecipeProduct) {
      priceProduct = mapProductContextToPriceProductForNonPortionProducts(
        productData.code,
        selectedDimensionSet,
        basketLineData,
        productData.getIngredients('Topping', selectedDimensionSet),
      )
    } else if (productData.isPortionProduct && portions && portions.length > 0) {
      priceProduct = mapProductContextToPriceProductForPortionProducts(productData.code, selectedDimensionSet, portions)
    }

    if (priceProduct) {
      fetchPrice(priceProduct)
    }
  }
  const getPrice = (product: BasketLineData, halfLeft?: BasketLineData, halfRight?: BasketLineData) => {
    const menuPricing = menuPrice(product)
    const shouldUseMenuPrice =
      menuPricing.formattedPrice !== null && (product.item?.type !== 'Portion' || !halfLeft?.item || !halfRight?.item)

    if (shouldUseMenuPrice) {
      setProductPricing(menuPricing)

      // when managed to get the price from the menu, no need to run to the api or extract default
      return
    }

    fallbackPrice.current = defaultPrice(product)
    if (product.item?.type === 'Portion' || shouldFetchPrice(product, productPricing)) {
      const mappedProduct =
        product.item?.type === 'Portion'
          ? mapBasketLineDataToHalfNHalfPriceProduct(product, [halfLeft, halfRight])
          : mapBasketLineDataToPriceProduct(product)
      fetchPrice(mappedProduct)
    } else {
      setProductPricing(fallbackPrice.current)
    }
  }

  const resetPrice = () => {
    setProductPricing({
      formattedPrice: null,
      formattedSalePrice: null,
      formattedPromoPrice: null,
    })
  }

  useEffect(() => {
    if (dynamicPricing) {
      setProductPricing(dynamicPricing)
    }
  }, [dynamicPricing])

  useEffect(() => {
    if (!!error) {
      setProductPricing(fallbackPrice.current)
    }
  }, [error])

  return {
    ...productPricing,
    getPrice,
    getPriceForDimensionalProduct,
    resetPrice,
  }
}
