/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { applicationName } from '@dominos/business/functions'
import { OrderStatus } from '../order/order-types'
import {
  AnalyticSender,
  AutoCompleteEvent,
  EnhancedEcommercePurchaseEvent,
  FeatureEventSender,
  VoucherCodeDeclinedEvent,
  VoucherCodeSource,
  VoucherProcessingStatus,
} from './analytics'
import { SecuritySender } from './security'

export type Source = 'Deeplink' | 'Organic' | 'Form' | 'Aggregator' | 'Internal' | 'Menu Set'

const voucherCodeSource: Record<Source, VoucherCodeSource> = {
  Form: 'Form',
  Deeplink: 'Link',
  'Menu Set': 'Offers',
  Organic: 'Offers', // Is this an offer?
  Aggregator: 'Offers', // Is this an offer?
  Internal: 'Offers', // Is this an offer?
}

export const reportCheckout = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  sendFeatureEvent: FeatureEventSender,
  purchaseEvent: EnhancedEcommercePurchaseEvent | undefined,
  basket?: Basket,
  currentStore?: Bff.Stores.Store,
  currentOrder?: CurrentOrderDetailsReducerProps,
) => {
  const reportStoreSelected = (selectedStore: Bff.Stores.Store) => {
    sendSecurity('Flow.StoreSearch.StoreSelected', {
      'Order ID': currentOrder?.orderId,
      'Store Number': selectedStore.storeNo,
    })
  }

  const reportStoreDiverted = (selectedStore: Bff.Stores.Store, divertedToStore: Bff.Stores.Store) => {
    sendSecurity('Flow.TimedOrder.StoreDiverted', {
      'Order ID': currentOrder?.orderId,
      'Store Number': selectedStore.storeNo,
      'Diverted To Store Number': divertedToStore.storeNo,
    })
  }

  const reportDeliveryAddressSelected = () => {
    sendSecurity('Flow.AddressSearch.DeliveryAddressSelected', {
      'Order ID': currentOrder?.orderId,
    })
  }

  const reportAlternativeStoreSelected = (storeNo: number, originalStoreNo?: number) => {
    sendSecurity('Flow.OrderTime.AlternativeStoreSelected', {
      'Order ID': currentOrder?.orderId,
      'Store Number': storeNo,
      'Original Store Number': originalStoreNo,
      platform: applicationName(),
    })
  }

  const reportOrderTime = (time: string) => {
    sendSecurity('Flow.OrderTime.TimeSelected', {
      'Order ID': currentOrder?.orderId,
      'Store Number': currentStore?.storeNo,
      Time: time,
    })

    sendAnalytics('order_action', {
      order_timing: time === 'ASAP' ? 'now' : 'later',
    })
  }

  const reportOrderStatus = (status?: OrderStatus, retryCount: number = 0) => {
    sendSecurity('Flow.OrderStatus.StatusReceived', {
      'Order ID': currentOrder?.orderId,
      'Order Status': status,
      'Retry Count': retryCount,
    })
  }

  const reportEstimatedOrderTime = (timeRange: `${number}-${number}` | null, status: OrderStatus | null) => {
    sendAnalytics('Estimated Order Time', {
      estimated_time_range: timeRange,
      order_status: status,
    })
  }

  const reportPaymentMethodSelected = (PaymentProvider: string, PaymentMethod: string) => {
    sendSecurity(
      'Flow.Payment.MethodSelected',
      {
        PaymentProvider,
        PaymentMethod,
        'Order ID': currentOrder?.orderId,
        'Store Number': currentStore?.storeNo,
      },
      true,
    )

    sendAnalytics('Payment Method', {
      'Store Name': currentStore?.media?.name,
      'Store Id': currentStore?.storeNo,
      'Service Method': currentOrder?.basketHeaderData?.serviceMethod,
      'Service Method Subtype': currentOrder?.basketHeaderData?.serviceMethodSubType,
      'Payment Provider': PaymentProvider as BffContext.PaymentProviders,
      'Payment Method': PaymentMethod as BffContext.PaymentMethods,
    })
  }

  const reportPaymentCompleted = (PaymentResult?: string, EventSource?: string) => {
    sendSecurity(
      'Flow.Payment.Completed',
      {
        EventSource,
        PaymentResult,
        'Order ID': currentOrder?.orderId,
        'Store Number': currentStore?.storeNo,
      },
      true,
    )
  }

  const reportPaymentFailed = (error: string | undefined) => {
    sendSecurity(
      'Flow.Payment.Failed',
      {
        PaymentResult: error,
        'Order ID': currentOrder?.orderId,
        'Store Number': currentStore?.storeNo,
      },
      true,
    )
  }

  const reportNextAction = (nextActionPayload: { data?: {} }) => {
    const nextActionPayloadClone = {
      ...nextActionPayload,
    }
    delete nextActionPayloadClone.data
    sendSecurity(
      'Flow.Payment.NextAction',
      {
        'Order ID': currentOrder?.orderId,
        'Store Number': currentStore?.storeNo,
        PaymentResult: JSON.stringify(nextActionPayloadClone),
      },
      true,
    )
  }

  const reportOutstandingBalance = (outstandingBalance: number) => {
    sendSecurity('Flow.Payment.OutstandingBalance', {
      'Order ID': currentOrder?.orderId,
      'Store Number': currentStore?.storeNo,
      PaymentResult: JSON.stringify({ outstandingBalance }),
    })
  }

  const reportRedirectBack = (data: {}) => {
    sendSecurity(
      'Flow.Payment.RedirectBack',
      {
        'Order ID': currentOrder?.orderId,
        'Store Number': currentStore?.storeNo,
        PaymentResult: JSON.stringify(data),
      },
      true,
    )
  }

  const reportVoucherUsage = (code: string, source: Source) => {
    sendSecurity('Flow.Voucher.VoucherEntered', {
      Code: code,
      Source: source,
      'Order ID': currentOrder?.orderId,
      'Store Number': currentStore?.storeNo,
    })

    sendAnalytics('Voucher Code Entered', {
      'Voucher Code': code,
      'Voucher Code Source': voucherCodeSource[source],
    })
  }

  const reportVoucherAccepted = (
    code: string,
    description: string,
    value?: number,
    clearPartialAnalytics: boolean = false,
  ) => {
    sendSecurity('Flow.Voucher.VoucherProcessed', {
      Code: code,
      'Order ID': currentOrder?.orderId,
    })

    sendAnalytics('Voucher Code Processed', {
      'Voucher Code': code,
      'Voucher Description': description,
      'Voucher Processing Status': 'Accepted',
      'Voucher Value': value,
      ...(clearPartialAnalytics ? { 'Voucher Code Source': '' } : {}),
    })
  }

  const reportVoucherDeclined = (
    status: VoucherProcessingStatus,
    reason?: string,
    clearPartialAnalytics: boolean = false,
  ) => {
    sendSecurity('Flow.Voucher.VoucherProcessed', {
      'Declined Reason': reason,
      'Order ID': basket?.id,
    })

    sendAnalytics('Voucher Code Processed', {
      'Voucher Processing Status': status,
      ...(reason ? { 'Voucher Declined Reason': reason } : {}),
      ...(clearPartialAnalytics
        ? {
            'Voucher Code': '',
            'Voucher Code Source': '',
          }
        : {}),
    } as VoucherCodeDeclinedEvent)
  }

  const reportVoucherRemoved = (code: string, source: Source, value: number) => {
    sendSecurity('Flow.Voucher.VoucherRemoved', {
      Code: code,
      Source: source,
      'Order ID': currentOrder?.orderId,
      'Store Number': currentStore?.storeNo,
    })

    sendAnalytics('Voucher Code Removed', {
      'Voucher Code': code,
      'Voucher Code Source': voucherCodeSource[source],
      'Voucher Value': value,
    })
  }

  const reportPurchase = (event: EnhancedEcommercePurchaseEvent | undefined, callback?: () => void) => {
    sendAnalytics(
      'EE - Purchase',
      basket?.lines?.length === 0 && event
        ? {
            ...event,
            Payment: {
              ...event?.Payment,
              'Service Method': currentOrder?.basketHeaderData?.serviceMethod,
              'Service Method Subtype': currentOrder?.basketHeaderData?.serviceMethodSubType,
              'Payment Method': currentOrder?.paymentMethod?.paymentMethod,
              'Payment Provider': currentOrder?.paymentMethod?.providerCode,
            },
          }
        : purchaseEvent,
      callback,
    )
    sendFeatureEvent('purchases')
    sendFeatureEvent('revenue', purchaseEvent?.Order.Revenue)
    sendFeatureEvent('number-of-items-purchase', basket?.lines?.length)
  }

  const reportInsufficientDataFromMobile = (missingFields: string[]) => {
    sendSecurity('Flow.Checkout.InsufficientDataFromMobile', {
      missingFields: missingFields.join(','),
      'Order ID': basket?.id,
      'Store Number': currentStore?.storeNo,
    })
  }

  const reportOrderReset = (automaticallyReset: boolean = false) => {
    sendSecurity('Flow.Order.Reset', {
      'Order ID': basket?.id,
      'Automatically Reset': automaticallyReset,
    })
  }

  const reportInitOrderPayloadEmpty = (payment: Bff.Orders.InitialiseOrderPayment | undefined) => {
    sendSecurity(
      'Flow.Checkout.InitOrderPayloadEmpty',
      {
        'Order ID': currentOrder?.orderId ?? basket?.id,
        'Store Number': currentStore?.storeNo,
        OrderDetails: JSON.stringify(currentOrder),
        BasketDetails: JSON.stringify(basket),
        PaymentDetails: JSON.stringify(payment),
      },
      true,
    )
  }

  const reportAutoCompleteUsage = (status: AutoCompleteEvent['status']) => {
    sendAnalytics('AutoComplete Address Search', {
      event: 'google_autocomplete_address_search',
      status,
    })
  }

  return {
    reportStoreSelected,
    reportStoreDiverted,
    reportDeliveryAddressSelected,
    reportAlternativeStoreSelected,
    reportOrderTime,
    reportOrderStatus,
    reportEstimatedOrderTime,
    reportPaymentMethodSelected,
    reportPaymentCompleted,
    reportPaymentFailed,
    reportNextAction,
    reportOutstandingBalance,
    reportRedirectBack,
    reportVoucherUsage,
    reportVoucherAccepted,
    reportVoucherDeclined,
    reportVoucherRemoved,
    reportPurchase,
    reportInsufficientDataFromMobile,
    reportOrderReset,
    reportInitOrderPayloadEmpty,
    reportAutoCompleteUsage,
  }
}
