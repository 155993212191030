import React, { useState } from 'react'
import { MyDetailsFormProps } from './my-details-form.interface'
import { MyDetailsFormEditable } from './my-details-form-editable'
import { MyDetailsFormReadOnly } from './my-details-form-read-only'

// TODO: refactor with DeliveryDetailsForm and PickUpDetailsForm
export const MyDetailsForm = (props: MyDetailsFormProps) => {
  const [editMyDetails, setEditMyDetails] = useState(false)

  return props.readOnly && props.initialDetails && !editMyDetails ? (
    <MyDetailsFormReadOnly
      initialDetails={props.initialDetails}
      setEditMyDetails={setEditMyDetails}
      testID={props.testID}
    />
  ) : (
    <MyDetailsFormEditable {...props} />
  )
}
