import DeliveryDetailsForm from './delivery-details-form'
import PickUpDetailsForm from './pick-up-details-form'
import React, { useCallback, useMemo, useState } from 'react'
import styles from './details-container.less'
import { countFromBasketLines } from '@dominos/business/functions/basket'
import { mapEClub } from './helpers'
import { rootActions } from '@dominos/business'
import { ServiceMethodSubTypes } from '@dominos/interfaces/checkout'
import { ServiceMethodType } from './service-method-type/service-method-type'
import { TermsAndConditions } from './terms-and-conditions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { OrderButton, RenderOrderButtonArgs, ServiceMethodDetailsFormProps } from '@dominos/components'
import { renderInlineSingleItemOffer } from '@dominos/components/checkout/common/render-single-item-offer'
import {
  noop,
  useBasket,
  useBasketValidation,
  useCurrentOrderDetails,
  useFeatures,
  useNativeAppStoredData,
  useServiceMethod,
  useServiceSubTypes,
  useTermsAndConditionsInitialState,
} from '@dominos/hooks-and-hocs'

export const DEFAULT_PICKUP_SUBTYPE: ServiceMethodSubTypes = 'InStorePickUp'
export const DEFAULT_DELIVERY_SUBTYPE: ServiceMethodSubTypes = 'ReceiveDirectly'

export const ServiceMethodDetailsForm = ({
  customerOrderDetails,
  isMyDetailsValid,
  eClub,
  testID,
  onComplete,
}: ServiceMethodDetailsFormProps) => {
  const dispatch = useDispatch()
  const serviceMethod = useServiceMethod()
  const { t } = useTranslation('checkout')
  const [checkoutOffersEnabled, pickUpTypesEnabled] = useFeatures('CheckoutMyDetailsOffers', 'EnablePickupSubTypes')
  const { basket: basketData, error: basketError, pending: basketLoading } = useBasket()
  const { basketHeaderData: currentOrderData } = useCurrentOrderDetails()
  const { isBasketValid } = useBasketValidation(t)
  const { storeNativeAppCustomerDetails } = useNativeAppStoredData()

  const [serviceMethodSubType, setServiceMethodSubType] = useState<Bff.Stores.ServiceMethodSubTypes | undefined>(
    currentOrderData?.serviceMethodSubType ?? undefined,
  )

  const [termsValid, handleTermsAndConditionsChange] = useTermsAndConditionsInitialState()
  const itemCount = useMemo(() => countFromBasketLines(basketData.lines), [basketData.lines])

  const shouldOrderButtonDisabled = useCallback(
    (isExtraDetailsValid: boolean) =>
      !!basketError || !basketData || !isMyDetailsValid || !termsValid || !isExtraDetailsValid,
    [basketError, basketData, isMyDetailsValid, termsValid],
  )

  const placeOrder = (placeOrderHook: () => {}) => {
    const extraDetails = placeOrderHook()
    dispatch(rootActions.selectServiceMethodSubType(serviceMethodSubType))

    const customerDetails = {
      ...customerOrderDetails,
      ...extraDetails,
      eclub: mapEClub(eClub),
    }
    dispatch(rootActions.saveCustomerDetails(customerDetails))
    storeNativeAppCustomerDetails(customerDetails)
    onComplete()
  }

  const isPickUp = (serviceMethod?: BffContext.ServiceMethods) => serviceMethod === 'Pickup'

  const { pickupSubTypes, deliverySubTypes } = useServiceSubTypes()
  const subTypes = useMemo(
    () =>
      isPickUp(serviceMethod)
        ? (currentOrderData?.storeNo &&
            currentOrderData?.time !== undefined &&
            pickUpTypesEnabled && [DEFAULT_PICKUP_SUBTYPE, ...pickupSubTypes]) ||
          null
        : (!!deliverySubTypes.length && [DEFAULT_DELIVERY_SUBTYPE, ...deliverySubTypes]) || null,
    [pickupSubTypes, deliverySubTypes],
  )

  const serviceMethodTypeProps = {
    fieldId: isPickUp(serviceMethod) ? 'pick-up-type' : 'delivery-type',
    fieldName: isPickUp(serviceMethod) ? 'pickupSubType' : 'deliverySubType',
    defaultOption: isPickUp(serviceMethod) ? DEFAULT_PICKUP_SUBTYPE : DEFAULT_DELIVERY_SUBTYPE,
  }

  const onPlaceOrderPress = (placeOrderHook: () => {}) => () => {
    if (isBasketValid()) {
      placeOrder(placeOrderHook)
    }
  }

  const renderTermsAndConditions = () => (
    <TermsAndConditions
      testID={`${testID}.terms-and-conditions`}
      onTermsChange={handleTermsAndConditionsChange}
      isValid={termsValid}
    />
  )

  const renderOrderButton = ({ placeOrderHook, isAdditionalDetailsValid }: RenderOrderButtonArgs) => (
    <div className={styles.orderButtonContainer}>
      <OrderButton
        testID={`${testID}.place-order.button`}
        itemCount={itemCount}
        price={basketData.total || 0}
        onPress={onPlaceOrderPress(placeOrderHook)}
        disabled={shouldOrderButtonDisabled(isAdditionalDetailsValid)}
        text={t('Place Order')}
        fill
        loading={basketLoading}
      />
    </div>
  )

  const renderServiceMethodType = (extraProps = {}) =>
    !!subTypes && (
      <ServiceMethodType
        serviceMethod={serviceMethod!}
        subTypes={subTypes}
        checkedOption={serviceMethodSubType}
        onChecked={setServiceMethodSubType}
        {...serviceMethodTypeProps}
        {...extraProps}
      />
    )

  const ServiceMethodDetails = isPickUp(serviceMethod) ? PickUpDetailsForm : DeliveryDetailsForm

  return (
    <ServiceMethodDetails
      testID={testID}
      serviceMethodSubType={serviceMethodSubType}
      renderInlineSingleItemOffer={(checkoutOffersEnabled && renderInlineSingleItemOffer) || noop}
      renderTermsAndConditions={renderTermsAndConditions}
      renderOrderButton={renderOrderButton}
      renderServiceMethodType={renderServiceMethodType}
    />
  )
}
