import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { LoyaltyEnroll } from '@dominos/components'
import { AccountDetails } from '../account-details/account-details'
import { ActionButton } from '@dominos/components/buttons'
import { useCurrentOrderDetails, useDominosTheme, useFeatures } from '@dominos/hooks-and-hocs'
import css from './profile-update-form.less'
import { useTranslation } from 'react-i18next'

import { TermsAndConditionsGeneric } from '@dominos/components/terms-and-conditions'
import { TermsAndConditions } from '@dominos/components/create-account/terms-and-conditions'
import { completeCustomerProfileQueryBuilder } from '@dominos/components/profile/customer-profile-query-builder'
import { customerMutation } from '@dominos/business/queries'

export function ProfileUpdateForm({ customer, onUpdate }: { customer: Bff.Customers.Customer; onUpdate: () => void }) {
  const [isAccountDetailsValid, setIsAccountDetailsValid] = useState(false)
  const [loyaltyEnrolled, setLoyaltyEnrolled] = useState(false)
  const [subscriptions, setSubscriptions] = useState<Record<string, boolean | undefined>>({
    marketing: false,
    vouchers: false,
  })
  const [termsValid, setTermsValid] = useState(false)

  const { orderId } = useCurrentOrderDetails()

  const [accountDetails, setAccountDetails] = useState<{ [key: string]: string | undefined }>({
    Name: customer.name,
    Email: customer.email,
    AlternateName: customer.alternateName,
    Mobile: customer.phoneNumber,
  })

  const theme = useDominosTheme()
  const { t } = useTranslation('login')

  const [updateCustomerAccount, { loading }] = useMutation<
    { customer: Bff.Customers.Customer },
    { input: Bff.Customers.UpsertCustomer }
  >(customerMutation, {
    onCompleted(data) {
      if (data.customer) {
        onUpdate()
      }
    },
  })

  const [loyaltyFeatureEnabled, isGDPRDisclaimerEnabled, isLoyaltyMarketingEnabled] = useFeatures(
    'loyalty-backend',
    'createAccountGDPRDisclaimer',
    'LoyaltyMarketing',
  )

  const onMarketingConsentChange = (fieldName: string | null, isChecked: boolean) => {
    setSubscriptions({ ...subscriptions, [fieldName!]: isChecked })
  }

  const loyaltyUpdatesEnabled = !!subscriptions.loyalty
  const onLoyaltyMarketingConsentChange = (isChecked: boolean) => onMarketingConsentChange('loyalty', isChecked)

  const onFormValidationChange = (isValid: boolean) => {
    setIsAccountDetailsValid(isValid)
  }

  const handleSubmit = async () => {
    const customerUpdateFields = completeCustomerProfileQueryBuilder({
      id: customer.id,
      email: accountDetails.Email!,
      name: accountDetails.Name!,
      alternateName: accountDetails.AlternateName,
      phoneNumber: accountDetails.Mobile!,
      orderId,
      loyaltyEnrolled,
      subscriptions,
    })
    updateCustomerAccount({ variables: { input: customerUpdateFields } })
  }

  const onDetailsChange = (field: string, value: string | undefined) => {
    setAccountDetails((prev) => ({ ...prev, [field]: value }))
  }

  return (
    <form style={{ padding: 10 }} onSubmit={handleSubmit}>
      <p>{t('ProfileUpdateFormDescription', { defaultValue: 'A few more steps to update your profile.' })}</p>
      <AccountDetails
        onChange={onDetailsChange}
        onValidationChange={onFormValidationChange}
        hideEmail={!!customer?.email}
        hideName={!!customer?.name}
        hidePhoneNumber={!!customer?.phoneNumber}
        hideAlternateName
        email={customer?.email}
        name={customer?.name}
        alternateName={customer?.alternateName}
        phoneNumber={customer?.phoneNumber}
      />

      {loyaltyFeatureEnabled && (
        <LoyaltyEnroll
          enrolled={loyaltyEnrolled}
          onCheck={setLoyaltyEnrolled}
          receiveUpdatesEnabled={loyaltyUpdatesEnabled}
          onCheckReceiveUpdates={onLoyaltyMarketingConsentChange}
          showLoyaltyMarketing={isLoyaltyMarketingEnabled}
        />
      )}

      <TermsAndConditions setValid={setTermsValid} />

      <ActionButton
        testID='updateAccount.button'
        onPress={handleSubmit}
        text={t(`ProfileUpdateButton`, { defaultValue: 'Submit' })}
        loading={loading}
        disabled={!(isAccountDetailsValid && termsValid) || loading}
        textFontWeight='semibold'
        className={css.updateButton}
      />

      {!!isGDPRDisclaimerEnabled && (
        <div className={''}>
          <TermsAndConditionsGeneric
            testID={`ProfileUpdateForm.GDPRDisclaimer`}
            text={t(`GDPR Disclaimer`)}
            textStyle={{ color: theme.colours.web.grey, fontSize: 12 }}
            weight='bold'
          />
        </div>
      )}
    </form>
  )
}
