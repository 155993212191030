import { getFullUrl } from '@dominos/business/functions'
import { ProductUpsellBannerData } from '@dominos/components/product/product-upsell/product-upsell-component-banner/mock-product-upsell-banner'
import { TFunction } from 'react-i18next'

export type ProductUpsellCardViewProps = {
  testID?: string
  viewModel: ProductUpsellCardViewModel
  onYesClicked: () => void
  onNoClicked: () => void
}

type ProductUpsellCardViewModelProps = {
  t: TFunction<'menu'>
  upsellData: ProductUpsellBannerData
}

type ProductUpsellCardViewModel = {
  upgradeButtonText: string
  bannerImageUrl: string
  upsellName: string
}

export const createProductUpsellCardViewModel = ({
  t,
  upsellData,
}: ProductUpsellCardViewModelProps): ProductUpsellCardViewModel => {
  const upgradeButtonText = upsellData.price ? upsellData.price : t('ProductComponentUpsellYes')
  const upsellName = upsellData.media.name.value
  const bannerImageUrl = getFullUrl(upsellData.media.bannerImage.uri)

  return { upgradeButtonText, bannerImageUrl, upsellName }
}
