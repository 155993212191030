import React from 'react'
import { Card, Modal } from '@dominos/components'
import { useTranslation } from 'react-i18next'
import { ProfileUpdateForm } from '../profile-update-form/profile-update-form'
import { ProfileHeader } from '../profile-header'

export const ProfileCompletionModal = ({
  onSubmit,
  onClose,
  testID,
  customer,
}: {
  onSubmit: () => void
  onClose: () => void
  testID: string
  customer: Bff.Customers.Customer
}) => {
  const { t } = useTranslation('login')

  return (
    <Modal testID={testID} onModalClose={onClose}>
      <Card testID={`${testID}.card`}>
        <ProfileHeader
          testID={`${testID}.cardHeader`}
          title={t('ProfileCompletionPopupTitle', { defaultValue: 'Continue' })}
          onDismiss={onClose}
        />
        <ProfileUpdateForm customer={customer} onUpdate={onSubmit} />
      </Card>
    </Modal>
  )
}
