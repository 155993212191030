import { getBasketHeaderData } from '@dominos/business/functions/basket'
import { getSelectedDeliveryAddress } from '../functions/address'
import { rootActions } from '@dominos/business/root.actions'
import { RoundUpForCharityDetails } from '@dominos/components/charity/round-up/use-round-up-for-charity'
import { OrderStatus } from '@dominos/hooks-and-hocs/order'
import { PaymentDetails } from '@dominos/interfaces'
import { getType } from 'typesafe-actions'
import uuid from 'uuid'

export interface CurrentOrderDetailsReducerProps extends Omit<BasketDetailsProps, 'basketHeaderData'> {
  paymentMethod?: PaymentDetails
  basketHeaderData?: Partial<BasketData>
  selectedDeliveryAddress?: StoredCustomerAddress
  transactionToken?: string
  orderStatus?: OrderStatus
  offersShown: string[]
  orderId: string
  advanceOrder: {
    id: string
    startedAt: string
    endedAt?: string
  }
  orderPaymentId: string
  edenredAuthCode?: string
  viewedProductOnMobile?: boolean
  roundUpForCharity?: RoundUpForCharityDetails
  tipAmount?: number
}

const orderInitState: CurrentOrderDetailsReducerProps = {
  offersShown: [],
  orderId: uuid(),
  advanceOrder: {
    id: uuid(),
    startedAt: new Date().toISOString(),
  },
  orderPaymentId: '',
  viewedProductOnMobile: false,
}

// Todo Update when we bring in timed order and order selection
// eslint-disable-next-line max-lines-per-function
export const currentOrderDetailsReducer: import('redux').Reducer<CurrentOrderDetailsReducerProps> = (
  state: CurrentOrderDetailsReducerProps = orderInitState,
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.viewedProductOnMobile): {
      return {
        ...state,
        viewedProductOnMobile: action.payload,
      }
    }
    case getType(rootActions.addCustomerAddressToOrder):
      return {
        ...state,
        basketHeaderData: {
          ...state.basketHeaderData,
          storeNo: action.address.storeNo,
        },
        selectedDeliveryAddress: action.address,
      }
    case getType(rootActions.selectServiceMethod):
      return {
        ...orderInitState,
        basketHeaderData: {
          ...state.basketHeaderData,
          serviceMethod: action.payload,
        },
        selectedDeliveryAddress: action.payload === 'Pickup' ? undefined : state.selectedDeliveryAddress,
        orderId: uuid(),
        advanceOrder: state.advanceOrder,
      }
    case getType(rootActions.selectServiceMethodSubType):
      return {
        ...state,
        basketHeaderData: {
          ...state.basketHeaderData,
          serviceMethodSubType: action.payload,
        },
      }
    case getType(rootActions.updateCarDetails):
      return {
        ...state,
        basketHeaderData: {
          ...state.basketHeaderData,
          ...action.payload,
        },
      }
    case getType(rootActions.saveCustomerDetails):
      return { ...state, customerDetailsData: action.payload }
    case getType(rootActions.clearCustomerDetails):
      return { ...state, customerDetailsData: undefined }
    case getType(rootActions.resetCurrentStore):
      return {
        ...state,
        basketHeaderData: { ...state.basketHeaderData, storeNo: undefined, time: undefined },
      }
    case getType(rootActions.storeSelected):
    case getType(rootActions.retrievedStoreInformation):
      return {
        ...state,
        basketHeaderData: { ...state.basketHeaderData, storeNo: action.store?.storeNo },
        selectedDeliveryAddress: { ...state.selectedDeliveryAddress, storeNo: action.store?.storeNo },
      }
    case getType(rootActions.selectOrderTime):
      return {
        ...state,
        basketHeaderData: {
          ...state.basketHeaderData,
          time: action.payload.orderTime,
        },
      }
    case getType(rootActions.placeOrderSuccess):
      return { ...state, tipAmount: 0 }
    case getType(rootActions.orderFlowSuccess):
      return {
        ...state,
        advanceOrder: {
          ...state.advanceOrder,
          endedAt: state.advanceOrder.endedAt ?? new Date().toISOString(),
        },
      }
    case getType(rootActions.restartOrder):
      return {
        ...orderInitState,
        orderId: uuid(),
        advanceOrder: {
          id: uuid(),
          startedAt: new Date().toISOString(),
        },
      }
    case getType(rootActions.updatePaymentMethod):
      return {
        ...state,
        paymentMethod: { ...action.payload },
      }
    case getType(rootActions.setTransactionToken):
      return {
        ...state,
        transactionToken: action.payload,
      }
    case getType(rootActions.setOrderPaymentId):
      return {
        ...state,
        orderPaymentId: action.payload,
      }
    case getType(rootActions.checkoutFromNativeApp):
      return {
        ...state,
        orderId: action.payload.basketId,
        orderStatus: action.payload.event === 'tracker' ? state.orderStatus : undefined,
      }
    case getType(rootActions.importFosSessionFromNativeApp):
      return {
        ...state,
        advanceOrder: {
          ...state.advanceOrder,
          id: action.payload.id || state.advanceOrder.id,
          startedAt: action.payload.startedAt || state.advanceOrder.startedAt,
        },
      }
    case getType(rootActions.saveOrder):
      return {
        ...state,
        basketHeaderData: getBasketHeaderData(action.payload.order),
        selectedDeliveryAddress: getSelectedDeliveryAddress(action.payload.order),
      }
    case getType(rootActions.saveOrderToLocalState):
      return {
        ...state,
        basketHeaderData: getBasketHeaderData(action.payload),
        selectedDeliveryAddress: getSelectedDeliveryAddress(action.payload),
      }
    case getType(rootActions.setOrderStatus):
      return {
        ...state,
        orderStatus: action.payload,
      }
    case getType(rootActions.setOrderTime):
      return {
        ...state,
        basketHeaderData: {
          ...state.basketHeaderData,
          time:
            !!state.basketHeaderData && !!state.basketHeaderData.time
              ? state.basketHeaderData.time
              : new Date().toISOString(),
        },
      }
    case getType(rootActions.setOfferToShown):
      return {
        ...state,
        offersShown: [...state.offersShown, action.payload],
      }
    case getType(rootActions.setRoundUpForCharityDetails):
    case getType(rootActions.updateRoundUpForCharityDetails):
      return {
        ...state,
        roundUpForCharity: { ...action.payload },
      }
    case getType(rootActions.removeRoundUpForCharityDetails): {
      const { roundUpForCharity, ...rest } = state

      return {
        ...rest,
      }
    }
    case getType(rootActions.setEdenredAuthCode):
      return {
        ...state,
        edenredAuthCode: action.payload,
      }
    case getType(rootActions.setTipAmount):
      return {
        ...state,
        tipAmount: action.payload,
      }
    case getType(rootActions.setRequireExtraChange):
      return {
        ...state,
        basketHeaderData: {
          ...state.basketHeaderData,
          requireExtraChange: action.payload,
        },
      }
    case getType(rootActions.setIsInitiatingOrder):
      return {
        ...state,
        basketHeaderData: {
          ...state.basketHeaderData,
          isInitiatingOrder: action.payload,
        },
      }
    case getType(rootActions.appStartedForKioskWeb):
      return {
        ...state,
        basketHeaderData: {
          ...state.basketHeaderData,
          storeNo: action.payload.storeNo,
          serviceMethod: 'Pickup',
          time: null,
        },
      }
    default:
      return state
  }
}
