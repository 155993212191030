import React, { useMemo, useState } from 'react'
import { AdditionalAddressDetailsUpdate, DeliveryAddressAdditionalFieldsContainer } from '@dominos/components/address'
import { AddressMakeUpContainer, ServiceMethodAdditionalDetailsProps } from '@dominos/components'
import { convertToStoredCustomerAddress, getOriginalAddress } from '@dominos/business/functions/address'
import { DeliveryInstructions } from './delivery-instructions'
import { FormValue } from './details-container.interface'
import { mapAddressComponentToStoredCustomerAddressUpdate } from '@dominos/business/functions/address'
import { rootActions } from '@dominos/business'
import {
  useAdditionalDeliveryAddressSearchFeatures,
  useWcaAdditionalFields,
} from '@dominos/hooks-and-hocs/checkout/use-checkout-delivery-details'
import { useCountryCode, useCurrentOrderDetails, useOneTrust } from '@dominos/hooks-and-hocs'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const DeliveryDetailsForm = ({
  testID,
  renderInlineSingleItemOffer,
  renderServiceMethodType,
  renderTermsAndConditions,
  renderOrderButton,
}: ServiceMethodAdditionalDetailsProps) => {
  const dispatch = useDispatch()
  const consents = useOneTrust()
  const countryCode = useCountryCode()
  const { t } = useTranslation('delivery-address')
  const { displayAdditionalFieldsWca, shouldSaveExtraAddress } = useWcaAdditionalFields()
  const { selectedDeliveryAddress } = useCurrentOrderDetails()
  const { autoCompleteDeliveryEnabled, deliveryAddressSearchFeature } = useAdditionalDeliveryAddressSearchFeatures()

  const [deliveryInstructions, setDeliveryInstructions] = useState<string>('')

  const [extraAddress, setExtraAddress] = useState<{ [key: string]: FormValue }>({
    address: selectedDeliveryAddress?.customerAddress.streetNo || undefined,
    buildingName: selectedDeliveryAddress?.customerAddress.buildingName || undefined,
  })

  const [additionalAddressComponents, setAdditionalAddressComponents] = useState<
    AdditionalAddressDetailsUpdate | undefined
  >()

  const additionalAddressDetailsCorrect = useMemo(() => {
    if (autoCompleteDeliveryEnabled || deliveryAddressSearchFeature)
      return displayAdditionalFieldsWca ? additionalAddressComponents?.isValid ?? false : true

    return shouldSaveExtraAddress ? !!extraAddress.address : true
  }, [
    autoCompleteDeliveryEnabled,
    deliveryAddressSearchFeature,
    additionalAddressComponents,
    shouldSaveExtraAddress,
    extraAddress.address,
    displayAdditionalFieldsWca,
  ])

  const handleAddressChange = (field: string | null, value: FormValue) => {
    setExtraAddress((prev) => ({ ...prev, [field!]: value }))
  }

  const saveExtraAddress = () => {
    const { address, buildingName } = extraAddress

    if (address || buildingName) {
      const parsedAddress = convertToStoredCustomerAddress({
        storeNo: selectedDeliveryAddress!.storeNo!,
        rawAddress: getOriginalAddress(selectedDeliveryAddress!),
        address: address as string,
        buildingName: buildingName as string,
        name: selectedDeliveryAddress?.displayAddress,
      })

      parsedAddress.locale = selectedDeliveryAddress?.locale

      dispatch(rootActions.addCustomerAddressToOrder(parsedAddress))
      dispatch(rootActions.saveCustomerAddress(parsedAddress))
    }
  }

  const saveCustomerAddressAutoComplete = () => {
    if (selectedDeliveryAddress && countryCode) {
      const updatedCustomerAddress = mapAddressComponentToStoredCustomerAddressUpdate(
        t,
        selectedDeliveryAddress,
        additionalAddressComponents?.additionalAddressComponents ?? [],
        countryCode,
      )

      dispatch(rootActions.addCustomerAddressToOrder(updatedCustomerAddress.address))

      if (consents.Functional) dispatch(rootActions.saveCustomerAddress(updatedCustomerAddress.address))
    }
  }

  const placeOrderHook = () => {
    if (autoCompleteDeliveryEnabled || deliveryAddressSearchFeature) saveCustomerAddressAutoComplete()
    else if (shouldSaveExtraAddress) saveExtraAddress()

    return { deliveryInstructions }
  }

  return (
    <React.Fragment>
      {displayAdditionalFieldsWca && (
        <DeliveryAddressAdditionalFieldsContainer
          testID={`${testID}.delivery-address-additional-fields`}
          customerAddress={selectedDeliveryAddress?.customerAddress}
          onChange={setAdditionalAddressComponents}
        />
      )}
      {shouldSaveExtraAddress && (
        <AddressMakeUpContainer
          addressDetails={{
            address: extraAddress.address as string,
            buildingName: extraAddress.buildingName as string,
          }}
          onChange={handleAddressChange}
        />
      )}
      {renderInlineSingleItemOffer(`${testID}.my-details-offers`)}
      {renderServiceMethodType({})}
      <DeliveryInstructions testID={`${testID}.delivery-instructions`} onChange={setDeliveryInstructions} />
      {renderTermsAndConditions()}
      {renderOrderButton({
        placeOrderHook,
        isAdditionalDetailsValid: additionalAddressDetailsCorrect,
      })}
    </React.Fragment>
  )
}

export default DeliveryDetailsForm
