import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { getCustomerId } from '@dominos/business/functions/customer'
import { removeSavedPaymentMethodMutation, savedPaymentDetailsQuery } from '@dominos/business/queries'
import { CreditCard, Error, NotificationType, PopUpNotification, Spinner } from '@dominos/components'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs'
import { SavedPaymentProps } from 'packages/interfaces'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MySavedPaymentErrors } from './my-saved-payments-errors'
import styles from './my-saved-payments.less'
import { MySavedEdenred } from './my-saved-edenred'

const MySavedPayments = () => {
  const { t } = useTranslation('login')
  const [paymentMethods, setPaymentMethods] = useState<Bff.Customers.CustomerPaymentMethod[] | undefined>(undefined)
  const [savedPaymentRemoved, setSavedPaymentRemoved] = useState<SavedPaymentProps | undefined>()
  const [error, setError] = useState<ApolloError | undefined>()
  const [showPopup, setShowPopup] = useState<boolean | undefined>(false)
  const customerId = getCustomerId()
  const { orderId } = useCurrentOrderDetails()

  const { data: customerData, loading } = useQuery<{ customer: Bff.Customers.Customer | null }>(
    savedPaymentDetailsQuery,
    {
      variables: {
        customerId,
      },
      fetchPolicy: 'no-cache',
    },
  )

  const [removePaymentMethodMutation] = useMutation<
    {},
    { token: string; providerCode?: string; paymentMethod?: string; orderId?: string }
  >(removeSavedPaymentMethodMutation, {
    onError: (err: ApolloError) => {
      setError(err)
      setSavedPaymentRemoved(undefined)
    },
    onCompleted: () => {
      setShowPopup(true)
      removePaymentMethodElementFromList(savedPaymentRemoved?.paymentTokenRemoved!)
      setSavedPaymentRemoved(undefined)
    },
  })

  const removePaymentMethodElementFromList = (tokenRemoved: string) => {
    setPaymentMethods(paymentMethods?.filter((pm) => pm.tokenId !== tokenRemoved))
  }

  const removePaymentMethod = (token: string, providerCode?: string, paymentMethod?: string) => {
    setSavedPaymentRemoved({
      paymentTokenRemoved: token,
      paymentMethodRemoved: paymentMethod,
      providerCodeRemoved: providerCode,
    })
  }

  const onPopupClose = () => {
    setShowPopup(false)
  }

  useEffect(() => {
    setPaymentMethods(customerData?.customer?.paymentMethods)
  }, [customerData])

  useEffect(() => {
    if (savedPaymentRemoved?.paymentTokenRemoved) {
      removePaymentMethodMutation({
        variables: {
          orderId,
          token: savedPaymentRemoved.paymentTokenRemoved,
          providerCode: savedPaymentRemoved.providerCodeRemoved,
          paymentMethod: savedPaymentRemoved.paymentMethodRemoved,
        },
      })
    }
  }, [savedPaymentRemoved])

  return (
    <div className={styles.wrapper} data-testid='account-saved-payments'>
      {error && <Error tns={t} errorDefinitions={MySavedPaymentErrors} error={error} />}

      {showPopup && (
        <PopUpNotification
          notificationType={NotificationType.confirmation}
          heading={t('PaymentMethodRemoved', { defaultValue: 'Saved payment method removed' })}
          onClose={onPopupClose}
        />
      )}

      <div className={styles.content}>
        <h2>{t('MyAccountPaymentsHeading', { defaultValue: 'My Saved Payments' })}</h2>
        <p>
          {t('MyAccountPaymentsDescription', {
            defaultValue:
              'To save new payment details, next time you order, select Save Payment Details when entering your credit card.',
          })}
        </p>
        {loading ? (
          <Spinner testID='payment-spinner' />
        ) : paymentMethods && paymentMethods.length > 0 ? (
          paymentMethods
            .sort((pa, pb) =>
              pa.paymentProvider < pb.paymentProvider ? -1 : pa.paymentProvider > pb.paymentProvider ? 1 : 0,
            )
            .map((pm) => {
              switch (pm.paymentMethod) {
                case 'CreditCard':
                  return (
                    <CreditCard
                      key={pm.tokenId}
                      card={pm}
                      removing={savedPaymentRemoved?.paymentTokenRemoved === pm.tokenId}
                      onRemove={removePaymentMethod}
                    />
                  )
                case 'Edenred':
                  return (
                    <MySavedEdenred
                      key={pm.tokenId}
                      paymentMethods={paymentMethods}
                      tokenId={pm.tokenId}
                      removePaymentMethod={removePaymentMethod}
                      savedPaymentRemoved={savedPaymentRemoved}
                    />
                  )
              }
            })
        ) : (
          <p className={styles.noMethods}>
            {t('MyAccountNoSavedPayments', { defaultValue: 'You have not saved any payment methods.' })}
          </p>
        )}
      </div>
    </div>
  )
}

export { MySavedPayments }
