/* eslint-disable @typescript-eslint/no-namespace */
import { Config } from '../common/get-config'
import { LoadScriptProps } from '../common/load-script'

import { processScriptForOneTrust } from '../common/process-script-for-one-trust'

export const setupRokt = (applicationConfig: Config, loadScript: (props: LoadScriptProps) => void) => {
  if (applicationConfig.ROKT_TAG_ID !== '' && applicationConfig.ROKT_TAG_ID !== 'null') {
    window._ROKT_ = 'rokt'
    loadScript({
      id: 'rokt',
      setupScriptFunc: (script) => {
        processScriptForOneTrust(script, '4', applicationConfig.ONE_TRUST_KEY)
        script.text = `(function (w, d, s) {
            var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s)
            j.async = true
            j.fetchpriority='high'
            j.src = 'https://apps.rokt.com/wsdk/integrations/launcher.js'
            j.id = "rokt-launcher"
            f.parentNode.insertBefore(j, f)
          })(window, document, 'script')`
        script.crossOrigin = 'anonymous'
      },
    })
  }
}
