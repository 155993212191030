import {
  useAccountDetailsSubmenuToggles,
  useBreakpoints,
  useCustomer,
  useDominosTheme,
  useFeatures,
} from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigationConstants } from '../../common/navigation/navigation-constants'
import { NewVouchersIndicator } from '@dominos/components/new-vouchers-indicator'
import { DeleteAccount } from './delete-account'
import styles from './my-account-layout.less'
import { MyDetails } from './my-details'
import { MyPassword } from './my-password'
import { MyRewards } from './my-rewards'
import { MySavedPayments } from './my-saved-payments'
import { MySavedVouchers } from './my-saved-vouchers'
import { SubMenuToggles } from '@dominos/interfaces'
import { MyInvoice } from './my-invoice'

interface SubMenuProps {
  subMenuToggles: SubMenuToggles
}

interface ContentPaneProps {
  defaultRoute: string
  subMenuToggles: SubMenuToggles
}

const RoutesComponent = (showSubMenu: boolean, subMenuToggles: SubMenuToggles, redirect?: string) => (
  <Routes>
    {redirect && <Route path='/' element={<Navigate replace to={redirect} />} />}
    {showSubMenu && <Route path='/' element={<SubMenu subMenuToggles={subMenuToggles} />} />}
    {subMenuToggles?.myRewards && <Route path='my-rewards' element={<MyRewards testID={''} />} />}
    {!subMenuToggles?.myRewards && <Route path='my-rewards' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.myAccountDetails && <Route path='details' element={<MyDetails />} />}
    {!subMenuToggles?.myAccountDetails && <Route path='details' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.myAccountPassword && <Route path='password' element={<MyPassword />} />}
    {!subMenuToggles?.myAccountPassword && <Route path='password' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.myAccountSavedPayments && <Route path='payment-details' element={<MySavedPayments />} />}
    {!subMenuToggles?.myAccountSavedPayments && <Route path='payment-details' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.savedVouchers && <Route path='vouchers' element={<MySavedVouchers />} />}
    {!subMenuToggles?.savedVouchers && <Route path='vouchers' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.myAccountInvoices && <Route path='invoice' element={<MyInvoice />} />}
    {!subMenuToggles?.myAccountInvoices && <Route path='invoice' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.myAccountDelete && <Route path='delete' element={<DeleteAccount />} />}
    {!subMenuToggles?.myAccountDelete && <Route path='delete' element={<Navigate replace to='/' />} />}
  </Routes>
)

const MyAccountLayout = () => {
  const { isMobile } = useBreakpoints()
  const { isLoggedIn } = useCustomer()
  const { subMenuToggles, getFirstActiveToggle } = useAccountDetailsSubmenuToggles()
  const toggles = subMenuToggles()
  const firstActiveToggle = getFirstActiveToggle()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn === false) {
      navigate(NavigationConstants.home)
    }
  }, [isLoggedIn])

  return isLoggedIn ? (
    <div className={styles.page} data-testid='account-navigation'>
      <div className={styles.wrapper}>
        <div className={styles.submenuMobile}>
          {isMobile ? RoutesComponent(true, toggles) : <SubMenu subMenuToggles={toggles} />}
        </div>
        {!isMobile && <ContentPane defaultRoute={firstActiveToggle} subMenuToggles={toggles} />}
      </div>
    </div>
  ) : null
}

const SubMenu = ({ subMenuToggles }: SubMenuProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('login')
  const [notificationBadgeMySavedCoupons] = useFeatures('NotificationBadgeMySavedCoupons')

  return (
    <ul className={styles.submenu} data-testid='my-account-submenu'>
      {subMenuToggles.myRewards && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/my-rewards'}>
            <h3>{t('MyAccountRewardsHeading', { defaultValue: 'My Rewards' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.myAccountDetails && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/details'}>
            <h3>{t('AccountDetailsHeading', { defaultValue: 'My Details' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.myAccountPassword && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/password'}>
            <h3>{t('MyAccountPasswordHeading', { defaultValue: 'My Password' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.myAccountSavedPayments && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/payment-details'}>
            <h3>{t('MyAccountPaymentsHeading', { defaultValue: 'My Saved Payments' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.savedVouchers && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/vouchers'}>
            <h3>{t('MyAccountSavedVouchersTitle', { defaultValue: 'My Saved Vouchers' })}</h3>
            {notificationBadgeMySavedCoupons && <NewVouchersIndicator showCount={true} />}
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.myAccountInvoices && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/invoice'} data-testid='my-account-invoice'>
            <h3>{t('MyAccountSubMenuInvoice', { defaultValue: 'Request an E-Invoice' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.myAccountDelete && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/delete'}>
            <h3>{t('MyAccountSubMenuDeleteAccount', { defaultValue: 'Delete Account' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
    </ul>
  )
}

const ContentPane = ({ defaultRoute, subMenuToggles }: ContentPaneProps) => (
  <div className={styles.contentPane} data-testid='my-account-content-pane'>
    {RoutesComponent(true, subMenuToggles, defaultRoute)}
  </div>
)

export { MyAccountLayout }
