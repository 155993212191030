export * from './account-icon'
export * from './account-checked-icon'
export * from './account-no-neck-icon'
export * from './apple-icon'
export * from './bell'
export * from './bouncing-dots'
export * from './calendar'
export * from './change'
export * from './chevron'
export * from './circle-half-empty-right'
export * from './clock'
export * from './confirmation-tick'
export * from './cross'
export * from './delivery-icon'
export * from './disclaimer'
export * from './dominos-logo-icon'
export * from './edenred-icon'
export * from './exclamation-icon'
export * from './face-symbols'
export * from './facebook-icon'
export * from './email-icon'
export * from './feedback-submitted-icon'
export * from './geo-icon'
export * from './gift-card-partial-payment-icon'
export * from './gmo-aumobile-icon'
export * from './gmo-docomo-icon'
export * from './gmo-linepay-icon'
export * from './gmo-rakuten-icon'
export * from './gmo-softbank-icon'
export * from './google-icon'
export * from './portion-empty-left'
export * from './portion-empty-right'
export * from './header-logo-icon'
export * from './house-icon'
export * from './info-icon'
export * from './info-icon-solid'
export * from './loading-oval'
export * from './logout-icon'
export * from './magnifying-glass-icon'
export * from './menu-icon'
export * from './message-icon'
export * from './minus-icon'
export * from './my-order-icon'
export * from './new-address-icon'
export * from './offers-icon'
export * from './order-failed-icon'
export * from './payment-error-icon'
export * from './paypal-icon'
export * from './pick-up-icon'
export * from './plus-icon'
export * from './question-icon'
export * from './recent-icon'
export * from './recent-icon-light'
export * from './trash-can'
export * from './rewards-enroll'
export * from './rewards-icon'
export * from './rewards-nav-icon'
export * from './robot-delivery-icon'
export * from './saved-icon'
export * from './search-result-icon'
export * from './session-expired'
export * from './store-details-delivery'
export * from './store-details-pick-up'
export * from './tracker-chef'
export * from './tracker-cooking'
export * from './tracker-receipt'
export * from './tracker-scooter'
export * from './tracker-star'
export * from './tracker-store'
export * from './tracker/pickup/call-store'
export * from './tracker/pickup/car-park-delivery'
export * from './tracker/pickup/outside-store'
export * from './upgrade-icon'
export * from './vouchers-icon'
export * from './backspace-icon'
export * from './outside-delivery-area'
export * from './no-location-icon'
export * from './x-mark'
export * from './mobile-mpk-icon'
export * from './pin-icon'
export * from './cart-icon'
export * from './portion-empty-top-left'
export * from './portion-empty-top-right'
export * from './portion-empty-bottom-left'
export * from './portion-empty-bottom-right'
export * from './list-icon'
export * from './map-icon'
export * from './map-arrow-icon'
export * from './map-arrow-icon-solid'
export * from './touch-icon'
export * from './terminal-icon'
export * from './selection-tick'
export * from './portion-product'
export * from './portion-empty-left-side-icon'
export * from './portion-empty-right-side-icon'
export * from './portion-empty-top-left-side-icon'
export * from './portion-empty-top-right-side-icon'
export * from './portion-empty-bottom-left-side-icon'
export * from './portion-empty-bottom-right-side-icon'
export * from './phone-icon'
export * from './mail-icon'
