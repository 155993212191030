import { useEffect, useMemo, useState } from 'react'
import { useConfigContext, useDevToggles, useFeatures } from '@dominos/hooks-and-hocs'
import { loadScript, setupRokt } from '@dominos/business/functions'

export const useRokt = () => {
  const { applicationConfig } = useConfigContext()
  const [launcher, setLauncher] = useState<Rokt.launcherInstance>()
  const [attributes, setAttributes] = useState<Rokt.placementAttributes>()
  const [roktEnabled] = useFeatures('RoktAdvertisements')
  const { isEnabled } = useDevToggles()
  const available = useMemo(
    () =>
      roktEnabled &&
      applicationConfig.ROKT_TAG_ID &&
      applicationConfig.ROKT_TAG_ID !== 'null' &&
      !isEnabled['disable-rokt-advertisements'],
    [roktEnabled, applicationConfig, isEnabled],
  )

  useEffect(() => {
    if (!available) {
      return
    }

    const initializeRokt = async () => {
      await new Promise<void>((resolve) => {
        if (window.Rokt) {
          resolve()
        } else {
          const roktScript = document.getElementById('rokt-launcher')
          if (roktScript) {
            roktScript.onload = () => resolve()
          }
        }
      })

      const launcherInstance = await window.Rokt?.createLauncher({
        accountId: applicationConfig.ROKT_TAG_ID,
        sandbox: applicationConfig.ROKT_SANDBOX === 'true',
        pageInitTimestamp: new Date(),
      })

      setLauncher(launcherInstance)
    }

    setupRokt(applicationConfig, loadScript)
    initializeRokt()

    return () => {
      if (launcher) {
        launcher.terminate()
      }
    }
  }, [applicationConfig, roktEnabled])

  useEffect(() => {
    if (launcher && attributes && attributes.attributes) {
      const selectPromise = selectPlacements(attributes.attributes, attributes.identifier)

      return () => {
        if (selectPromise) {
          selectPromise.then((selection) => selection.close())
        }
      }
    }
  }, [launcher, attributes])

  const selectPlacements = (
    { menuCategories = [], menuSections = [], payments = [], ...attrs }: Rokt.SetAttributesInput,
    identifier?: string,
  ) => {
    if (available && launcher) {
      return launcher.selectPlacements({
        attributes: {
          email: attrs.email,
          amount: attrs.amount,
          currency: attrs.currency,
          quantity: attrs.quantity,
          confirmationref: attrs.orderId,
          firstname: attrs.name,
          mobile: attrs.mobile,
          zipcode: attrs.postCode,
          city: attrs.city,
          state: attrs.state,
          experience: attrs.experience,
          majorcat: menuCategories.join('/'),
          minorcat: menuSections.join('/'),
          paymenttype: payments[0]?.paymentMethod,
          delivery: attrs.delivery ? 'True' : 'False',
          country: attrs.country,
        },
        identifier: identifier,
      })
    }
  }

  const setRoktAttributes = (attrs: Rokt.SetAttributesInput, identifier?: string) => {
    setAttributes({ attributes: attrs, identifier })
  }

  return {
    setRoktAttributes,
  }
}
