import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActionButton } from '@dominos/components'
import { useConfirm } from '@dominos/hooks-and-hocs/application/use-confirm'
import { useFeatures } from '@dominos/hooks-and-hocs/features'
import { useForgeRock } from '@dominos/hooks-and-hocs/authentication/forgerock'
import { useAccountDelete } from '@dominos/hooks-and-hocs/customer/use-account-delete'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'

import styles from './delete-account.less'

const DeleteAccount = () => {
  const { deleteAccount, inProgress } = useAccountDelete()
  const { confirm } = useConfirm()
  const [forgeRockIdentityProviderEnabled] = useFeatures('ForgeRockIdentityProvider')
  const { deleteAccountForgeRock } = useForgeRock()
  const { t } = useTranslation('login')

  const onDeleteAccount = () => {
    const customerVerified = confirm(
      t('DeleteAccountConfirmationDialog', { defaultValue: 'Are you sure you want to delete your account?' }),
    )

    if (!customerVerified) {
      return
    }
    if (forgeRockIdentityProviderEnabled) {
      isNativeApp() ? notifyNativeApp('delete-oauth-account') : deleteAccountForgeRock()

      return
    }
    deleteAccount()
  }

  return (
    <div className={styles.wrapper}>
      <h2>{t('DeleteAccountHeading', { defaultValue: 'Delete Account' })}</h2>
      <p>
        {t('DeleteAccountDescription', {
          defaultValue:
            'This will delete your account and you will not be able to log on again using your saved credentials. This cannot be undone.',
        })}
      </p>
      <div className={styles.centerButton}>
        <ActionButton
          loading={inProgress}
          disabled={inProgress}
          containerStyle={{ backgroundColor: '#e21836' }}
          onPress={onDeleteAccount}
          testID={'delete-account-action-button'}
        >
          {t('DeleteAccountButton', { defaultValue: 'Delete Account' })}
        </ActionButton>
      </div>
    </div>
  )
}

export { DeleteAccount }
