import { ApplicationConfig, getApplicationConfig } from './get-application-config'

import configAuDev from '../../../../applications/olo.web/config/config.au.json'
import configAuRelease from '../../../../applications/olo.web/config/config.au.release.json'
import configBeDev from '../../../../applications/olo.web/config/config.be.json'
import configBeRelease from '../../../../applications/olo.web/config/config.be.release.json'
import configDeDev from '../../../../applications/olo.web/config/config.de.json'
import configDeRelease from '../../../../applications/olo.web/config/config.de.release.json'
import configDkDev from '../../../../applications/olo.web/config/config.dk.json'
import configDkRelease from '../../../../applications/olo.web/config/config.dk.release.json'
import configFrDev from '../../../../applications/olo.web/config/config.fr.json'
import configFrRelease from '../../../../applications/olo.web/config/config.fr.release.json'
import configJpDev from '../../../../applications/olo.web/config/config.jp.json'
import configJpRelease from '../../../../applications/olo.web/config/config.jp.release.json'
import configLuDev from '../../../../applications/olo.web/config/config.lu.json'
import configLuRelease from '../../../../applications/olo.web/config/config.lu.release.json'
import configNlDev from '../../../../applications/olo.web/config/config.nl.json'
import configNlRelease from '../../../../applications/olo.web/config/config.nl.release.json'
import configNzDev from '../../../../applications/olo.web/config/config.nz.json'
import configNzRelease from '../../../../applications/olo.web/config/config.nz.release.json'
import configSgDev from '../../../../applications/olo.web/config/config.sg.json'
import configSgRelease from '../../../../applications/olo.web/config/config.sg.release.json'
import configMyDev from '../../../../applications/olo.web/config/config.my.json'
import configMyRelease from '../../../../applications/olo.web/config/config.my.release.json'
import configKhDev from '../../../../applications/olo.web/config/config.kh.json'
import configKhRelease from '../../../../applications/olo.web/config/config.kh.release.json'
import configTwDev from '../../../../applications/olo.web/config/config.tw.json'
import configTwRelease from '../../../../applications/olo.web/config/config.tw.release.json'

export type Locale =
  | 'en-AU'
  | 'de-DE'
  | 'nl-NL'
  | 'en-NZ'
  | 'nl-BE'
  | 'fr-FR'
  | 'fr-LU'
  | 'ja-JP'
  | 'da-DK'
  | 'en-SG'
  | 'ms-MY'
  | 'km-KH'
  | 'zh-tw'

export interface CountrySpecificConfig {
  COUNTRY: BffContext.Countries
  LANGUAGES: string
  ENVIRONMENT: string
  CURRENCY_CODE: 'AUD' | 'NZD' | 'EUR' | 'JPY' | 'DKK' | 'SGD' | 'MYR' | 'KHR' | 'TWD'
  CURRENCY_FORMAT_LOCALE:
    | 'en-AU'
    | 'DE'
    | 'NL'
    | 'en-NZ'
    | 'nl-BE'
    | 'de-FR'
    | 'nl-LB'
    | 'JA'
    | 'da-DK'
    | 'en-SG'
    | 'ms-MY'
    | 'km-KH'
    | 'zh-tw'
  APPINSIGHTS_INSTRUMENTATION_KEY: string
  APPINSIGHTS_ROLE_NAME: string
  APPLEID_CLIENT_ID: string
  FACEBOOK_SDK_APP_ID: string
  GOOGLE_CLIENT_ID: string
  GOOGLE_MAPS_API_KEY: string
  GOOGLE_PLACES_DELIVERY_TYPES: string
  GOOGLE_PLACES_PICKUP_TYPES: string
  GOOGLE_MAP_ID_DELIVERY_SEARCH: string
  DELIVERY_SEARCH_AVG_CHARACTERS: string
  LOCALE_DEFAULT: Locale
  DOMAIN_SUFFIX: string
  REGION_DOMAIN_SUFFIX: string
  ADYEN_CLIENT_KEY: string
  ADYEN_ENVIRONMENT: string
  ADYEN_LOGO_URL: string
  ONE_TRUST_KEY: string
  GTM_CONTAINER_ID: string
  VWO_ACCOUNT_ID: string
  RECAPTCHA_V3_SITE_KEY: string
  RECAPTCHA_V2_SITE_KEY: string
  GPS_DRIVER_TRACKER_URL: string
  FOS_COLLECTION_URL: string
  NEXTGEN_GPS_DRIVER_TRACKER_URL: string
  PRODUCT_IMAGE_BASE_URL: string
  BUILD_BUILDNUMBER: string
  NEW_RELIC_ALLOWED_ORIGINS: string
  NEW_RELIC_APPLICATION_ID: string
  ROKT_TAG_ID: string
  ROKT_SANDBOX: string
  APOLLO_RETRY: string
  ROLLOUT: boolean
  EDENRED_URL: string
  EDENRED_CLIENT_ID: string
  EDENRED_ACR_VALUES: string
  PAYPAL_CLIENT_ID: string
  SWRVE_API_KEY: string
  SWRVE_APP_ID: string | number | null
  SWRVE_STACK: 'us' | 'eu'
  B2B_AZURE_AD_CLIENT_ID: string
  B2B_APPLICATION_HOSTNAMES: string
  FORGEROCK_AM_URL: string
  KIOSK_APPLICATION_HOSTNAMES: string
  PANDU_AMBIL_POTONG_Q_URL: string
  DELIVERY_ADDRESS_SEARCH_MATCH_RADIUS: string
  LAUNCH_DARKLY_CLIENT_SIDE_ID: string
  ASIAPAY_REDIRECT_URL: string
}

export const countrySpecificConfig: { [K in BffContext.Countries]?: CountrySpecificConfig } = {
  AU: configAuDev as CountrySpecificConfig,
  BE: configBeDev as CountrySpecificConfig,
  DE: configDeDev as CountrySpecificConfig,
  DK: configDkDev as CountrySpecificConfig,
  FR: configFrDev as CountrySpecificConfig,
  JP: configJpDev as CountrySpecificConfig,
  LU: configLuDev as CountrySpecificConfig,
  NL: configNlDev as CountrySpecificConfig,
  NZ: configNzDev as CountrySpecificConfig,
  SG: configSgDev as CountrySpecificConfig,
  MY: configMyDev as CountrySpecificConfig,
  KH: configKhDev as CountrySpecificConfig,
  TW: configTwDev as CountrySpecificConfig,
}

export const countrySpecificConfigProd: { [K in BffContext.Countries]?: CountrySpecificConfig } = {
  AU: configAuRelease as CountrySpecificConfig,
  BE: configBeRelease as CountrySpecificConfig,
  DE: configDeRelease as CountrySpecificConfig,
  DK: configDkRelease as CountrySpecificConfig,
  FR: configFrRelease as CountrySpecificConfig,
  JP: configJpRelease as CountrySpecificConfig,
  LU: configLuRelease as CountrySpecificConfig,
  NL: configNlRelease as CountrySpecificConfig,
  NZ: configNzRelease as CountrySpecificConfig,
  SG: configSgRelease as CountrySpecificConfig,
  MY: configMyRelease as CountrySpecificConfig,
  KH: configKhRelease as CountrySpecificConfig,
  TW: configTwRelease as CountrySpecificConfig,
}

export type Config = ApplicationConfig & CountrySpecificConfig

export const getCountryConfig = (): Config => {
  const applicationConfig = getApplicationConfig()
  const countryConfig = getCountryBasedConfig(applicationConfig)
  const substituteDomain = (s: string) =>
    s
      ?.replace('{{countryDomainSuffix}}', countryConfig.DOMAIN_SUFFIX)
      ?.replace('{{regionDomainSuffix}}', countryConfig.REGION_DOMAIN_SUFFIX)

  const substitutedApplicationConfig = {
    ...applicationConfig,
    GRAPH_URL: substituteDomain(applicationConfig.GRAPH_URL),
    B2B_GRAPH_URL: substituteDomain(applicationConfig.B2B_GRAPH_URL),
    KIOSK_GRAPH_URL: substituteDomain(applicationConfig.KIOSK_GRAPH_URL),
    TRANSLATIONS_LOAD_PATH: substituteDomain(applicationConfig.TRANSLATIONS_LOAD_PATH),
  }

  return {
    ...substitutedApplicationConfig,
    ...countryConfig,
    ADYEN_ENVIRONMENT: applicationConfig.OVERRIDE_ADYEN_ENVIRONMENT || countryConfig.ADYEN_ENVIRONMENT,
    ADYEN_CLIENT_KEY: applicationConfig.OVERRIDE_ADYEN_CLIENT_KEY || countryConfig.ADYEN_CLIENT_KEY,
    ADYEN_LOGO_URL: applicationConfig.OVERRIDE_ADYEN_LOGO_URL || countryConfig.ADYEN_LOGO_URL,
  }
}

const getCountryBasedConfig = (applicationConfig: ApplicationConfig) => {
  const hostname = applicationConfig.OVERRIDE_DOMAIN || window?.location?.hostname
  const envCountrySpecificConfig = __DEV__ ? countrySpecificConfig : countrySpecificConfigProd
  const kioskSingleURL = applicationConfig.KIOSK_SINGLE_URL_HOSTNAMES

  let countryConfig: CountrySpecificConfig
  if ((kioskSingleURL ?? '').split(',').includes(hostname)) {
    const countryCode =
      localStorage.getItem('__DPE_KIOSK_COUNTRY__') ?? applicationConfig.DEFAULT_REGION_COUNTRY_CODE ?? 'AU'
    countryConfig = envCountrySpecificConfig[countryCode as BffContext.Countries]!
  } else {
    countryConfig =
      Object.values(envCountrySpecificConfig).find(
        (c) => hostname?.endsWith(c.DOMAIN_SUFFIX) || hostname?.includes(`olo-site-${c.COUNTRY.toLowerCase()}`),
      ) ||
      envCountrySpecificConfig[applicationConfig.DEFAULT_REGION_COUNTRY_CODE as BffContext.Countries]! ||
      envCountrySpecificConfig.AU!
  }

  return countryConfig
}
