import { CollapsableTitledCard } from '@dominos/components/cards'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './inline-upsell-card.less'
import { ActionButton } from '@dominos/components/buttons'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { useUpsellOffer } from '@dominos/hooks-and-hocs/offers'
import { useFeatures, useKiosk, useReport } from '@dominos/hooks-and-hocs'
import { EventSourceType } from '@dominos/hooks-and-hocs/logging/analytics/analytics-interfaces'

interface InlineUpsellCardProps {
  testID: string
}

const DEBOUNCE = 400

const UPSELL_OFFER_OPTIONS = {
  allowedLayout: 'SingleItem',
  locationType: 'ProductDetails',
  offerLocationCode: 'Menu.Basket',
}

export const InlineUpsellCard = ({ testID }: InlineUpsellCardProps) => {
  const { t } = useTranslation(['basket', 'menu'])
  const basketAddonsItems = useUpsellOffer(UPSELL_OFFER_OPTIONS)
  const dispatch = useDispatch()
  const { reportProductAddToCart } = useReport()
  const productInfoUrl = t('InlineUpsellProductInformationURL')
  const { isKioskOrder } = useKiosk()
  const [inlineUpsellProductInformationEnabled] = useFeatures('InlineUpsellProductInformation')
  const showProductInfo = inlineUpsellProductInformationEnabled && !isKioskOrder && productInfoUrl

  const addToOrder = (item: Bff.Offers.Item) => {
    const value: BasketLine[] = [
      {
        productCode: item.linkedItem.itemCode,
        quantity: 1,
        media: {
          name: item.name,
        },
        type: 'BasketLine',
        itemNo: 0,
        totalPrice: null,
        sizeCode: item.linkedItem.subItemCode,
      },
    ]
    dispatch(rootActions.addLinesToBasket({ add: value }))
    reportProductAddToCart(value, EventSourceType.InlineUpsell)
  }

  const handleProductInfoPress = () => {
    window.open(productInfoUrl)
  }

  return (
    <>
      {basketAddonsItems.length >= 1 ? (
        <div data-testid={`${testID}.container`} className={css.container}>
          <CollapsableTitledCard
            primaryTitle={t('BasketInlineUpsell')}
            noPadding={true}
            startCollapsed={false}
            testID={`${testID}.voucher-card`}
          >
            <div data-testid={`${testID}.addons-container`} className={css.addonsContainer}>
              {basketAddonsItems.map<React.ReactNode>((item, index) => (
                <React.Fragment key={item.id}>
                  <div
                    key={item.id}
                    data-testid={`${testID}.${item.id}.item.container`}
                    className={css.addonsTextContainer}
                  >
                    <p className={css.addonsNameText} data-testid={`${testID}.${item.id}.item.name`}>
                      {item.name}
                    </p>
                    <p className={css.addonsPriceText} data-testid={`${testID}.${item.id}.item.price`}>
                      {item.price}
                    </p>
                    <ActionButton
                      className={css.addonButtonContainer}
                      debounce={DEBOUNCE}
                      testID={`${testID}.${item.id}.item.button.add`}
                      text={t('menu:ADD')}
                      onPress={() => addToOrder(item)}
                      textFontWeight='semibold'
                    />
                  </div>
                  {index < basketAddonsItems.length - 1 && <div className={css.addOnsSeparator} />}
                </React.Fragment>
              ))}
              {showProductInfo && (
                <ActionButton
                  className={css.productInfoContainer}
                  debounce={DEBOUNCE}
                  testID={`${testID}.product.information`}
                  text={t('InlineUpsellProductInformation')}
                  onPress={handleProductInfoPress}
                  textFontWeight='semibold'
                />
              )}
            </div>
          </CollapsableTitledCard>
        </div>
      ) : null}
    </>
  )
}
