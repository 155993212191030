import { CrustIcon, ProductIngredientCard, SauceIcon, SizeIcon } from '@dominos/components'
import { ProductCustomiserProps } from '@dominos/components/product/product-customiser/'
import { mockProductUpsellBanner } from '@dominos/components/product/product-upsell/product-upsell-component-banner/mock-product-upsell-banner'
import { ProductUpsellComponentBanner } from '@dominos/components/product/product-upsell/product-upsell-component-banner/product-upsell-component-banner'
import {
  ProductUpsellZoneId,
  UpsellComponentType,
} from '@dominos/components/product/product-upsell/product-upsell.interface'
import { useBreakpoints, useDevToggles } from '@dominos/hooks-and-hocs'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getScrollProvider, scrollTo } from '../product-card/functions'
import { CustomisationCard } from './customisation-card'

export const NUM_COLUMNS = 3

export const ProductCustomiser = ({
  sizeData,
  onSizeChange,
  baseData,
  onBaseChange,
  sauceData,
  onSauceChange,
  toppingData,
  onToppingChange,
  calculateCurrentIngredientCount,
}: ProductCustomiserProps) => {
  const { isEnabled } = useDevToggles()
  const upsellBannerComponentEnabled = isEnabled['enable-upsell-banner-component']
  const { t } = useTranslation('menu')
  const { isMobile } = useBreakpoints()
  const scrollRef = useRef<HTMLDivElement>(null)
  const isSizeCustomisable = sizeData && sizeData.items.length > 0
  const isBaseCustomisable = baseData && baseData.items.length > 0
  const isSauceCustomisable = sauceData && sauceData.items.length > 0
  const isToppingCustomisable =
    toppingData && toppingData.currentIngredients.length > 0 && !!calculateCurrentIngredientCount
  const setScrollOffset = (distance: number) => scrollTo(distance, getScrollProvider(isMobile, scrollRef.current))

  const handleUpsellAccepted = (change: TouchableGridElement, componentType: UpsellComponentType) => {
    //TODO: MAB-3897 "Upgrade" Button Functionality
  }

  const handleUpsellDeclined = () => {
    //TODO: MAB-3897 "Declined" Button Functionality
  }

  return (
    <>
      {isSizeCustomisable && (
        <CustomisationCard
          testID={'Size-Selector'}
          items={sizeData.items}
          selectedItemCode={sizeData.selectedItemCode}
          primaryTitle={t('Size')}
          numColumns={NUM_COLUMNS}
          renderItem={SizeIcon}
          onItemSelected={onSizeChange}
        />
      )}
      {isBaseCustomisable && (
        <>
          {upsellBannerComponentEnabled && (
            <ProductUpsellComponentBanner
              testID={`product-upsell-card`}
              onUpsellAccepted={handleUpsellAccepted}
              onUpsellDeclined={handleUpsellDeclined}
              t={t}
              zoneId={ProductUpsellZoneId.ProductBaseUpsellBanner}
              upsellData={mockProductUpsellBanner}
              options={{ persistDismissed: true, showConfirmation: true }}
            />
          )}
          <CustomisationCard
            testID={'Base-Selector'}
            items={baseData.items}
            selectedItemCode={baseData.selectedItemCode}
            primaryTitle={t('Base')}
            numColumns={NUM_COLUMNS}
            renderItem={CrustIcon}
            onItemSelected={onBaseChange}
          />
        </>
      )}
      {isSauceCustomisable && (
        <CustomisationCard
          testID={'Sauce-Selector'}
          items={sauceData.items}
          selectedItemCode={sauceData.selectedItemCode}
          primaryTitle={t('Sauce')}
          numColumns={NUM_COLUMNS}
          renderItem={SauceIcon}
          onItemSelected={onSauceChange}
        />
      )}
      {isToppingCustomisable && (
        <ProductIngredientCard
          primaryTitle={t('Ingredients on this Pizza')}
          recipeIngredientCodes={toppingData?.recipeIngredientCodes}
          currentIngredients={toppingData?.currentIngredients}
          possibleIngredients={toppingData?.possibleIngredients}
          rules={toppingData?.ingredientRules}
          onItemChange={onToppingChange}
          toppingLineChange={toppingData?.changes}
          onSectionChange={setScrollOffset}
          calculateCurrentIngredientCount={calculateCurrentIngredientCount}
        />
      )}
    </>
  )
}
