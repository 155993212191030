import {
  BaseProductEditor,
  BottomBar,
  HalfHalfButtonCard,
  ProductCustomiser,
  useProductContext,
} from '@dominos/components'
import {
  createBasketLineSwap,
  getCurrentIngredients,
  useBaseData,
  useSauceData,
  useSizeData,
  useToppingData,
} from '@dominos/components/product/functions'
import { useDevToggles, useDominosTheme, useFeatures, useReport } from '@dominos/hooks-and-hocs'
import React from 'react'
import ProductBuildYourOwn from '../product-build-your-own/product-build-your-own'
import { RecipeProductCardProps } from './recipe-product-card.interface'
import { ToppingUpsellContainer } from '../product-card/topping-upsell'

export const RecipeProductCard = ({ testID, addToBasket, onDismiss, isEditing, t }: RecipeProductCardProps) => {
  const theme = useDominosTheme()
  const [productBuildYourOwnEnabled] = useFeatures('ProductBuildYourOwnEnabled')
  const {
    dimensionSetState,
    productData,
    saveBasketLineDataChanges,
    currentBasketLineData,
    createBasketLine,
    initialQuantity,
    switchProduct,
    formattedPrice,
    formattedPromoPrice,
    isValidForBasket,
  } = useProductContext()

  const { reportCreateBYO, reportProductCardInteraction, reportCreateHalfHalf } = useReport()

  const sizeData = useSizeData(productData, dimensionSetState)
  const baseData = useBaseData(productData, dimensionSetState)

  const sauceIngredients = productData?.getIngredients('Sauce', dimensionSetState?.selectedDimensionSet) || []
  const sauceRules = productData?.getIngredientTypeRule('Sauce', dimensionSetState?.selectedDimensionSet)
  const defaultSauce = sauceIngredients?.find((ingredient) => ingredient.inRecipe === true)
  const sauceData = useSauceData(sauceIngredients, currentBasketLineData?.sauce, sauceRules?.minQuantity === 0)

  const toppingIngredients = productData?.getIngredients('Topping', dimensionSetState?.selectedDimensionSet) || []
  const toppingRules =
    productData?.getIngredientTypeRule('Topping', dimensionSetState?.selectedDimensionSet) || undefined
  const toppingData = useToppingData(toppingIngredients, currentBasketLineData?.toppings, toppingRules)

  const isBuildYourOwnHalfHalfEnabled = productData?.features?.buildYourOwnHalfHalf?.isEnabled || false
  const isBuildYourOwnQuattroEnabled = productData?.features?.buildYourOwnQuattro?.isEnabled || false
  const isBuildYourOwnEnabled =
    productBuildYourOwnEnabled && (isBuildYourOwnHalfHalfEnabled || isBuildYourOwnQuattroEnabled)

  const { isEnabled } = useDevToggles()
  const toppingUpsellEnabled = isEnabled['topping-upsell']

  const onBaseChange = (baseCode: string) => {
    dimensionSetState?.handleDimensionChange('Base', baseCode)
  }

  const onSizeChange = (sizeCode: string) => {
    dimensionSetState?.handleDimensionChange('Size', sizeCode, true)
  }
  const onSauceChange = (sauceCode: string) => {
    saveBasketLineDataChanges('sauce', createBasketLineSwap(sauceCode, defaultSauce?.code))
  }
  const onToppingChange = (changes: BasketLineChange[]) => {
    saveBasketLineDataChanges('toppings', changes)
  }
  const addToBasketHandler = (quantity: number) => {
    addToBasket(createBasketLine(quantity))
    onDismiss(true)
  }

  const handleBuildYourOwn = (targetProductCode: string | undefined, byoType: 'halfhalf' | 'quattro') => {
    if (productData?.code && targetProductCode) {
      switchProduct(targetProductCode, productData?.code)
      reportCreateBYO(byoType, productData?.code, targetProductCode)
    }
  }

  const handleLegacyHalfHalfButtonClicked = (targetProductCode: string | undefined) => {
    if (productData?.code && targetProductCode) {
      switchProduct(targetProductCode, productData?.code)
      reportCreateHalfHalf(productData?.code, targetProductCode)
    }
  }

  const handleBuildYourOwnExpanded = (expanded: boolean) => {
    reportProductCardInteraction('product-build-your-own', expanded ? 'expand' : 'collapse')
  }

  return (
    <>
      <BaseProductEditor
        testID={`${testID}.base-product-editor`}
        onDismiss={onDismiss}
        t={t}
        formattedPrice={formattedPrice}
        formattedPromoPrice={formattedPromoPrice}
        //TODO: MAB-2600
        // energyKJ={energyKJ}
      >
        {toppingUpsellEnabled && <ToppingUpsellContainer testID={`${testID}.topping-upsell`} />}
        {isBuildYourOwnEnabled ? (
          <ProductBuildYourOwn
            t={t}
            color={theme.colours.actionBlue}
            testID={`${testID}.product-build-your-own`}
            isCreateHalfHalfEnabled={isBuildYourOwnHalfHalfEnabled}
            onCreateHalfHalfClick={() =>
              handleBuildYourOwn(productData?.features?.buildYourOwnHalfHalf?.settings?.productCode, 'halfhalf')
            }
            isCreateQuattroEnabled={isBuildYourOwnQuattroEnabled}
            onCreateQuattroClick={() =>
              handleBuildYourOwn(productData?.features?.buildYourOwnQuattro?.settings?.productCode, 'quattro')
            }
            onToggleExpanded={handleBuildYourOwnExpanded}
          />
        ) : (
          isBuildYourOwnHalfHalfEnabled && (
            <HalfHalfButtonCard
              buttonTitle={t('CreateHalfHalfButton')}
              testID={`${testID}.half-half-button-card`}
              onClick={() =>
                handleLegacyHalfHalfButtonClicked(productData?.features?.buildYourOwnHalfHalf?.settings?.productCode)
              }
            />
          )
        )}
        <ProductCustomiser
          sizeData={sizeData}
          onSizeChange={onSizeChange}
          baseData={baseData}
          onBaseChange={onBaseChange}
          sauceData={sauceData}
          onSauceChange={onSauceChange}
          toppingData={toppingData}
          onToppingChange={onToppingChange}
          calculateCurrentIngredientCount={(toppingChanges: BasketLineChange[]) =>
            getCurrentIngredients(toppingIngredients, toppingChanges).length
          }
        />
      </BaseProductEditor>
      <BottomBar
        testID={`${testID}.bottom-bar`}
        onComplete={addToBasketHandler}
        initialQuantity={initialQuantity}
        isPortionProduct={false}
        forceDisabled={!isValidForBasket}
        isEditing={isEditing}
      />
    </>
  )
}
