import { rootActions } from '@dominos/business'
import { DimensionFilter } from '@dominos/business/functions/menu'
import {
  isPortionMenuItem,
  isProductMenuItem,
  ProductCardSwitcher,
  ProductCardWrapper,
  ProductProvider,
} from '@dominos/components'
import { useBreakpoints, useKiosk, useMenu, useReport } from '@dominos/hooks-and-hocs'
import { EventSourceType } from '@dominos/hooks-and-hocs/logging/analytics/analytics-interfaces'
import { getStateFromNavigation, NavigationConstants } from '@dominos/navigation'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ProductSceneProps } from './product-scene.interface'

const TIMEOUT = 500

export const ProductScene = (props: ProductSceneProps) => {
  const { onDismiss } = props

  const { reportProductAddToCart } = useReport()
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const { itemsByCode } = useMenu()
  const navigate = useNavigate()
  const location = useLocation()

  const deepLinkProductCode = useParams().productCode

  const redirectUrl: string | undefined = getStateFromNavigation(location, 'redirectUrl') || props.redirectUrl
  const lineData: BasketLine = getStateFromNavigation(location, 'currentLine') || props.currentLine
  const isEditing = getStateFromNavigation<boolean | undefined>(location, 'isEditing') || props.isEditing
  const voucherNo = getStateFromNavigation<number | undefined>(location, 'voucherNo') || props.voucherNo
  const voucherItemNo = getStateFromNavigation<number | undefined>(location, 'voucherItemNo') || props.voucherItemNo
  const yPosition = getStateFromNavigation<number | undefined>(location, 'yPosition') || 0
  const swapping = getStateFromNavigation<BasketLine>(location, 'swapping') || props.swapping
  const dimensionFilter = getStateFromNavigation<DimensionFilter>(location, 'dimensionFilter') || props.dimensionFilter
  const addDirectlyToBasket =
    getStateFromNavigation<boolean | undefined>(location, 'addDirectlyToBasket') || props.addDirectlyToBasket
  const selectedProduct =
    getStateFromNavigation<MenuItemDependents | undefined>(location, 'currentProduct') || props.currentProduct
  const isDeepLink = !selectedProduct && deepLinkProductCode
  const product = selectedProduct || (deepLinkProductCode && itemsByCode?.[deepLinkProductCode])
  const currentProduct: ProductMenuItem | PortionMenuItem | undefined =
    product && (isProductMenuItem(product) || isPortionMenuItem(product)) ? product : undefined

  const isMobileLayout = isMobile || isKioskOrder

  const dispatch = useDispatch()
  const addToBasket = (item: BasketLine) => {
    if (swapping) {
      dispatch(
        rootActions.replaceBasketLine({
          remove: swapping,
          add: item,
          voucherNo,
          voucherItemNo,
        }),
      )
    } else if (lineData) {
      dispatch(
        rootActions.replaceBasketLine({
          remove: lineData,
          add: item,
          voucherNo,
          voucherItemNo,
        }),
      )
    } else {
      dispatch(rootActions.addLinesToBasket({ add: [item], voucherNo, voucherItemNo }))
    }
    reportProductAddToCart([item], EventSourceType.ProductCard)
  }

  useEffect(() => {
    if (!currentProduct || (!isMobileLayout && location.pathname.includes(NavigationConstants.product))) {
      navigate(NavigationConstants.menu)
    }
  }, [currentProduct])

  const handleProductSceneDismissal = () => onDismiss && onDismiss()

  const handleDismiss = (addedToBasket?: boolean) => {
    if (isDeepLink) {
      navigate(NavigationConstants.menu)
    } else if (isMobileLayout) {
      if (addedToBasket && !addDirectlyToBasket) {
        !!redirectUrl ? navigate(redirectUrl) : navigate(-1)
      } else {
        navigate(-1)
        dispatch(rootActions.viewedProductOnMobile(true))
        setTimeout(() => {
          window.scrollTo(0, yPosition || 0)
        }, TIMEOUT)
      }
    } else {
      handleProductSceneDismissal()
    }
    if (!isMobileLayout && addedToBasket && !!redirectUrl) {
      navigate(redirectUrl)
    }
  }

  return currentProduct ? (
    <ProductProvider product={currentProduct} basketLine={lineData} dimensionFilter={dimensionFilter}>
      <ProductCardWrapper testID='product-card-wrapper' onDismiss={handleProductSceneDismissal}>
        <ProductCardSwitcher
          currentProduct={currentProduct}
          lineData={lineData}
          isEditing={isEditing}
          onDismiss={handleDismiss}
          addToBasket={addToBasket}
          addDirectlyToBasket={addDirectlyToBasket}
        />
      </ProductCardWrapper>
    </ProductProvider>
  ) : (
    <></>
  )
}
