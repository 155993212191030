import React from 'react'
import { SpotlightProvider } from '@dominos/components/spotlight'
import {
  AuthenticationPopupHandler,
  DeepLinkHandler,
  KioskOrderHandler,
  LastOrderHandler,
  ProfileCompletionPopupHandler,
} from '@dominos/hooks-and-hocs'
import { B2BAuthenticationHandler } from '@dominos/hooks-and-hocs/authentication/b2b-authentication/b2b-authentication-handler'
import { UserInactivityMonitor } from '@dominos/hooks-and-hocs/session'
import { FosSessionImport, HistoryListener, ScrollToTop } from '../hocs'
import { KioskHeartbeat, KioskInactivityDetector } from '@dominos/components'

interface RouterContextsProps {
  children?: React.ReactNode
}

export const RouterContexts: React.FC<RouterContextsProps> = (props) => (
  <B2BAuthenticationHandler>
    <AuthenticationPopupHandler {...props}>
      <ProfileCompletionPopupHandler>
        <KioskOrderHandler {...props}>
          <UserInactivityMonitor {...props}>
            <DeepLinkHandler {...props}>
              <LastOrderHandler {...props}>
                <HistoryListener {...props}>
                  <FosSessionImport {...props}>
                    <SpotlightProvider>
                      <ScrollToTop {...props}>
                        <KioskInactivityDetector>
                          <KioskHeartbeat>{props.children}</KioskHeartbeat>
                        </KioskInactivityDetector>
                      </ScrollToTop>
                    </SpotlightProvider>
                  </FosSessionImport>
                </HistoryListener>
              </LastOrderHandler>
            </DeepLinkHandler>
          </UserInactivityMonitor>
        </KioskOrderHandler>
      </ProfileCompletionPopupHandler>
    </AuthenticationPopupHandler>
  </B2BAuthenticationHandler>
)
