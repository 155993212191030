import { countFromBasketLines } from '@dominos/business/functions/basket'
import { isNativeApp } from '@dominos/business/functions/native-app'
import { areVouchersComplete } from '@dominos/business/functions/basket/are-vouchers-complete'
import { RoundUpForCharityCheckout } from '@dominos/components/charity/round-up'
import {
  PaymentSetting,
  useBasket,
  useBasketValidation,
  useCurrentOrderDetails,
  useCurrentStore,
  useInitiateOrder,
  UseInitiateOrderProps,
  useKiosk,
  usePaymentAmount,
  usePlaceOrder,
  usePollTerminalPayment,
} from '@dominos/hooks-and-hocs'
import { usePlaceOrderErrorHandler } from '@dominos/hooks-and-hocs/checkout/usePlaceOrderErrorHandler'
import { NavigationConstants } from '@dominos/navigation'
import { useNavigate } from 'react-router-dom'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CancelPayment } from './cancel-payment/cancel-payment'
import { PayButton } from './pay-button/pay-button'
import { PaymentMethodProps } from './payment-method.interface'
import css from './payment-method.less'
import { PaymentOverlay } from './payment-overlay/payment-overlay'
import { TerminalActionOverlay } from './terminal/terminal-action-overlay'
import { useCustomerCheckoutDetails } from './use-customer-checkout-details'
import { NextStep } from '@dominos/hooks-and-hocs/checkout/place-order.interface'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { useFosOrderButtonClickedEvent } from '../use-fos-order-button-clicked-event'
import { isTerminalPayment } from '@dominos/business/functions/checkout'

export type SelectedPaymentSetting = (PaymentSetting & { adyenPaymentMethodType: string; methodId: string }) | undefined

const isNotQRCodeMethod = (methodId: string | undefined) =>
  !(methodId === 'MisterCash-bcmc_mobile' || methodId === 'PayNow-paynow' || methodId === 'DuitNow-duitnow')

// eslint-disable-next-line max-lines-per-function
export const PaymentMethod = ({
  methodId,
  children,
  paymentSetting,
  hidePaymentButton = false,
  invalid = false,
  selectedPaymentSetting,
  isValidatingBasket = false,
  transactionToken,
  paymentLimit,
  properties,
  onPaymentNextStep,
  outstandingBalance,
  onCustomInitiateOrder,
  overridePaymentButton: OverridePaymentButton,
  adyenPaymentMethodType,
  adyenPaymentDefaultName,
  shouldAutoPlaceOrder = false,
  onInitiateOrderReady,
  paymentButtonColor,
  paymentButtonIcon,
  paymentButtonIncludeTotal,
  paymentButtonTitle,
  paymentContentStyle,
  onInitiateOrderError,
}: PropsWithChildren<PaymentMethodProps>) => {
  const dispatch = useDispatch()
  const { isKioskOrder } = useKiosk()

  const { t } = useTranslation(isKioskOrder ? 'kiosk' : 'checkout')

  const { basket } = useBasket()
  const { id: basketId, lines: basketLines } = basket
  const currentStore = useCurrentStore()
  const currentOrder = useCurrentOrderDetails()
  const customerCheckoutDetails = useCustomerCheckoutDetails()
  const sendOrderButtonClickedEvent = useFosOrderButtonClickedEvent()
  const { isBasketValid } = useBasketValidation(t)
  const navigate = useNavigate()

  const [showProcessingOverlay, setShowProcessingOverlay] = useState<boolean>(false)
  const [showTerminalActionOverlay, setShowTerminalActionOverlay] = useState<boolean>(false)
  const hideAllOverlays = () => {
    setShowProcessingOverlay(false)
    setShowTerminalActionOverlay(false)
  }

  const [nextPaymentInProgress, setNextPaymentInProgress] = useState<boolean>(false)
  const [paymentCancelled, setPaymentCancelled] = useState<boolean>(false)
  const { paymentMethod, providerCode, donationEnabled } = paymentSetting
  const [updatedPayment, setUpdatedPayment] = useState<Bff.Orders.PlaceOrder.Payment | undefined>(undefined)
  const [pollTerminalStatus, setPollTerminalStatus] = useState<boolean>(false)
  const [pollCount, setPollCount] = useState<number>(0)
  const handlePlaceOrderError = usePlaceOrderErrorHandler({
    retry: () => {
      if (updatedPayment) placeOrder(basketId, updatedPayment)
    },
    noRetry: () => hideAllOverlays(),
    canRetry: !!updatedPayment,
  })

  const { paymentAmount, roundUpAmount, displayAmount } = usePaymentAmount(
    donationEnabled,
    outstandingBalance?.orderOutstandingBalance,
    paymentLimit,
  )
  const isNotQRCode = isNotQRCodeMethod(methodId)
  const isSelected = selectedPaymentSetting?.methodId === methodId
  const showCharity = !outstandingBalance?.orderOutstandingBalance && donationEnabled && isSelected

  const paymentDetails: Bff.Orders.InitialiseOrderPayment = {
    properties,
    paymentMethod,
    providerCode,
    amount: paymentAmount,
  }

  const onInitiatedOrder: UseInitiateOrderProps['onReady'] = ({ properties: propertiesFromInitOrder }) => {
    const passingThroughProps = propertiesFromInitOrder?.map(({ key, value }) => ({ key, value }))
    const payment = {
      ...paymentDetails,
      transactionToken,
      properties: passingThroughProps,
    } as Bff.Orders.PlaceOrder.Payment
    setUpdatedPayment(payment)

    if (isTerminalPayment(payment)) {
      setShowProcessingOverlay(false)
      setShowTerminalActionOverlay(true)
      setPollCount(0)
      setPollTerminalStatus(true)
    } else {
      placeOrder(basketId, payment)
    }
  }

  const { initiateOrder, loading: initiatingOrder } = useInitiateOrder({
    onReady: onInitiateOrderReady ?? onInitiatedOrder,
    handleInitiateOrderError: () => {
      hideAllOverlays()
      onInitiateOrderError?.()
    },
  })

  const handleNextPaymentStep = (nextStep?: NextStep | undefined) => {
    if (onPaymentNextStep && !paymentCancelled) {
      setNextPaymentInProgress(true)
      onPaymentNextStep(nextStep)

      if (nextStep && nextStep.nextStepType === 'OutstandingBalance' && nextStep.outstandingBalance) {
        hideAllOverlays()
      }
    }
  }

  const {
    placeOrder,
    loading: placingOrder,
    isPurchaseReported: placeOrderCompleted,
  } = usePlaceOrder({
    handlePlaceOrderError,
    onOrderConfirmed: () => {},
    onPaymentNextStep: handleNextPaymentStep,
  })

  useEffect(() => {
    if (isSelected && !donationEnabled) {
      dispatch(rootActions.removeRoundUpForCharityDetails())
    }
  }, [isSelected, donationEnabled])
  useEffect(() => {
    if (placeOrderCompleted) {
      hideAllOverlays()
      navigate(isNativeApp() ? NavigationConstants.nativeAppCheckoutProcessing : NavigationConstants.checkoutProcessing)
    }
  }, [placeOrderCompleted])

  const onPress = () => {
    if (!isBasketValid()) {
      hideAllOverlays()
    } else {
      setShowProcessingOverlay(true)
      setNextPaymentInProgress(false)
      setPaymentCancelled(false)
      sendOrderButtonClickedEvent()

      if (onCustomInitiateOrder) {
        onCustomInitiateOrder()
      } else {
        initiateOrder(paymentDetails, customerCheckoutDetails, paymentDetails.providerCode, adyenPaymentMethodType)
      }
    }
  }

  useEffect(() => {
    if (!invalid && !!transactionToken && shouldAutoPlaceOrder) {
      setShowProcessingOverlay(isNotQRCode)
      setShowTerminalActionOverlay(true)
      onPress()
    }
  }, [invalid, transactionToken, shouldAutoPlaceOrder])

  useEffect(() => {
    const orderdetails = {
      basket,
      currentStore,
      currentOrder,
    }
    localStorage.setItem('orderCheckoutDetails', JSON.stringify(orderdetails))
  }, [isSelected, currentOrder.paymentMethod])

  const itemCount = useMemo(() => countFromBasketLines(basketLines), [basketLines])

  const translationKey = adyenPaymentMethodType ? `${paymentMethod}-${adyenPaymentMethodType}` : paymentMethod
  const labelText = t(translationKey, adyenPaymentDefaultName && { defaultValue: adyenPaymentDefaultName })
  const labelTextSavedChecked = paymentSetting.savedPayment
    ? labelText + ' (' + t('saved', { defaultValue: 'Saved' }) + ')'
    : labelText

  const loading = isValidatingBasket || initiatingOrder || placingOrder
  const disablePayButton =
    itemCount === 0 ||
    invalid ||
    nextPaymentInProgress ||
    !areVouchersComplete(basketLines.filter((l) => l.type === 'BasketCoupon') as BasketCoupon[])

  const showCancelButton = showProcessingOverlay && !isNotQRCode

  const onCancelPress = () => {
    hideAllOverlays()
    setPaymentCancelled(true)
    if (onPaymentNextStep) {
      onPaymentNextStep()
    }
  }

  usePollTerminalPayment({
    pollTerminalStatus,
    setPollTerminalStatus,
    pollCount,
    setPollCount,
    currentOrder,
    hideAllOverlays,
  })

  return (
    <>
      {showTerminalActionOverlay && <TerminalActionOverlay showTerminalAction={true} />}
      {showProcessingOverlay && <PaymentOverlay showProcessing={isNotQRCode} />}
      <div className={css['payment-method']}>
        <input
          type='radio'
          value={[methodId || '', paymentMethod, providerCode, `${donationEnabled}`]}
          name='payment-method'
          role='tab'
          id={methodId}
          data-testid={`payment-method.${methodId}.tab`}
          checked={isSelected}
          readOnly
        />
        <label htmlFor={methodId}>{labelTextSavedChecked}</label>
      </div>
      <div
        className={isSelected ? css['payment-content'] : css.hidden}
        data-testid='payment-content'
        style={isSelected ? paymentContentStyle : undefined}
      >
        {showCharity && hidePaymentButton && (
          <RoundUpForCharityCheckout testID={`payment-method.${methodId}`} amount={roundUpAmount} />
        )}
        {showCancelButton ? <CancelPayment onCancel={onCancelPress}>{children}</CancelPayment> : children}
        {showCharity && !hidePaymentButton && (
          <RoundUpForCharityCheckout testID={`payment-method.${methodId}`} amount={roundUpAmount} />
        )}
        {hidePaymentButton ? (
          OverridePaymentButton ? (
            <OverridePaymentButton onPress={onPress} />
          ) : null
        ) : (
          <PayButton
            testID={`payment-method.${methodId}.payment-button`}
            onPress={onPress}
            loading={loading}
            disable={disablePayButton}
            icon={paymentButtonIcon}
            buttonColor={paymentButtonColor}
            title={paymentButtonTitle ?? t('Pay Now')}
            includeTotal={paymentButtonIncludeTotal ?? false}
            total={displayAmount}
          />
        )}
      </div>
    </>
  )
}
