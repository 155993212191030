import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation, useValidationJP } from '@dominos/business/functions/validation'
import { MobileField, ValidationTextField } from '@dominos/components'
import { useAccountValidation } from './use-account-validation'

const maxLengthEmail = 250
const maxLengthName = 250

interface AccountDetailsProps {
  email?: string
  name?: string
  alternateName?: string
  phoneNumber?: string
  onChange: (fieldName: string, value: string | undefined) => void
  onValidationChange: (isValid: boolean) => void
  hideName?: boolean
  hideEmail?: boolean
  hideAlternateName?: boolean
  hidePhoneNumber?: boolean
}
export const AccountDetails = (props: AccountDetailsProps) => {
  const { t } = useTranslation('login')
  const { isEmailValid, isRequired, maxLength, isNameValid } = useValidation()
  const { validateIsKatakana } = useValidationJP()
  const { fields, detailsValid, updateValidationState } = useAccountValidation(props)

  const onChange = (updatedField: Record<string, ValidationField>) => {
    const key = Object.keys(updatedField)[0]
    updateValidationState(key, updatedField[key].isValid)

    if (props.onChange) {
      props.onChange(key, updatedField[key].value)
    }
  }

  useEffect(() => {
    if (!props.onValidationChange) return

    props.onValidationChange(detailsValid)
  }, [detailsValid, props.onValidationChange])

  return (
    <>
      {props.hideEmail ? null : (
        <ValidationTextField
          key={fields.email}
          fieldName={fields.email}
          placeholder={t('CreateAccountEmailLabel', { defaultValue: 'Email' })}
          testID={`${fields.email}.field`}
          validationRules={[isRequired, isEmailValid, maxLength(maxLengthEmail)]}
          style={{ width: '100%' }}
          type='email'
          autoCapitalize={'none'}
          onChange={onChange}
          allowInitialValueResetWhenUndefined={true}
        />
      )}
      {props.hideName ? null : (
        <ValidationTextField
          key={fields.name}
          fieldName={fields.name}
          placeholder={t('CreateAccountNameLabel', { defaultValue: 'Name' })}
          testID={`${fields.name}.field`}
          validationRules={[isRequired, maxLength(maxLengthName), isNameValid]}
          style={{ width: '100%' }}
          onChange={onChange}
          allowInitialValueResetWhenUndefined={true}
        />
      )}
      {props.hideAlternateName ? null : (
        <ValidationTextField
          key={fields.alternateName}
          fieldName={fields.alternateName}
          placeholder={t('CreateAccountAlternateNameLabel', { defaultValue: 'Alternate Name' })}
          testID={`${fields.alternateName}.field`}
          validationRules={[isRequired, validateIsKatakana]}
          style={{ width: '100%' }}
          onChange={onChange}
          allowInitialValueResetWhenUndefined={true}
        />
      )}
      {props.hidePhoneNumber ? null : (
        <MobileField
          key={fields.mobile}
          fieldName={fields.mobile}
          style={{ width: '100%' }}
          onChange={onChange}
          allowInitialValueResetWhenUndefined={true}
        />
      )}
    </>
  )
}
