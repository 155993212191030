import React from 'react'
import css from './topping-upsell-container.less'
import { ProductIngredientItem } from '../../product-ingredient'
import { toppingUpsellIngredients } from './mock-topping-upsell-data'
import { useTranslation } from 'react-i18next'
import { DominosTheme } from '@dominos/hooks-and-hocs'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { CollapsableTitledCard } from '@dominos/components/cards'

interface Style {
  innerContainer: React.CSSProperties
}

const getStyles = (theme: DominosTheme): Style => ({
  innerContainer: {
    backgroundColor: theme.colours.actionGreenBackgound,
  },
})

const ToppingUpsellContainer = ({ testID }: BaseProps) => {
  const { t } = useTranslation('menu')
  const didChangeQuantity = () => {}
  const theme = useDominosTheme()
  const styles = getStyles(theme)

  const viewAllClick = () => {
    const toppingListHeader = document.getElementById('product-ingredient-card-header')
    const toppingList = document.getElementById('product-ingredient-card-children')

    if (toppingListHeader) {
      // if the toping list is collapsed, then expand it by clicking the header
      if (toppingList && window.getComputedStyle(toppingList).display === 'none') {
        toppingListHeader.click()
      }
      requestAnimationFrame(() => {
        toppingListHeader.scrollIntoView({ behavior: 'smooth' })
      })
    }
  }

  return (
    <CollapsableTitledCard
      testID={`${testID}`}
      noPadding={true}
      startCollapsed={false}
      primaryTitle={t('ToppingUpsellTitle', { defaultValue: 'Popular Extras' })}
    >
      <div className={css.ingredientContainer}>
        {toppingUpsellIngredients.map((ingredient) => (
          <ProductIngredientItem
            {...ingredient}
            key={ingredient.code}
            testID={`${testID}-topping-upsell-ingredient-${ingredient.code}`}
            onQuantityChange={didChangeQuantity}
            innerContainerStyle={styles.innerContainer}
          />
        ))}
      </div>
      <p data-testid={`${testID}.view-all-button`} role='button' onClick={viewAllClick} className={css.viewAllButton}>
        {t('ViewAllIngredientsButton', { defaultValue: 'View all ingredients' })}
      </p>
    </CollapsableTitledCard>
  )
}

export { ToppingUpsellContainer }
