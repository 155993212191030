import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useValidation } from '@dominos/business/functions/validation'
import { GenericSelect, ValidationTextField } from '@dominos/components'
import { detailsFieldNames } from '../details-container-config'
import { MyDetailsFieldsProps } from './my-details-form.interface'
import { TFunction } from 'i18next'

type CharityCodeType = '919' | '25885' | '333' | '885521'

export const MyDetailsTWExtraFields = (props: MyDetailsFieldsProps) => {
  const { t } = useTranslation('checkout')
  const { isUniformNumberValid, isMobileCarrierCodeValid, isCitizenCarrierCodeValid } = useValidation()
  const [charityCode, setCharityCode] = useState<CharityCodeType>()

  const handleCharityCodeOptions = (value: string) => {
    setCharityCode(value as CharityCodeType)
    const charityCodeUpdate = {
      [detailsFieldNames.charityCode]: { value: value, isValid: true },
    }
    props.handleChange(detailsFieldNames.charityCode)(charityCodeUpdate)
  }

  return (
    <>
      <ValidationTextField
        key={detailsFieldNames.uniformNumber}
        fieldName={detailsFieldNames.uniformNumber}
        placeholder={t('UniformNumber')}
        testID={`${detailsFieldNames.uniformNumber}.field`}
        validationRules={[isUniformNumberValid]}
        style={{ width: '100%' }}
        onChange={props.handleChange(detailsFieldNames.uniformNumber)}
      />
      <ValidationTextField
        key={detailsFieldNames.mobileCarrierCode}
        fieldName={detailsFieldNames.mobileCarrierCode}
        placeholder={t('MobileCarrierCode')}
        testID={`${detailsFieldNames.mobileCarrierCode}.field`}
        validationRules={[isMobileCarrierCodeValid]}
        style={{ width: '100%' }}
        onChange={props.handleChange(detailsFieldNames.mobileCarrierCode)}
      />
      <ValidationTextField
        key={detailsFieldNames.citizenCarrierCode}
        fieldName={detailsFieldNames.citizenCarrierCode}
        placeholder={t('CitizenCarrierCode')}
        testID={`${detailsFieldNames.citizenCarrierCode}.field`}
        validationRules={[isCitizenCarrierCodeValid]}
        style={{ width: '100%' }}
        onChange={props.handleChange(detailsFieldNames.citizenCarrierCode)}
      />

      <GenericSelect
        key={detailsFieldNames.charityCode}
        testID={`${detailsFieldNames.charityCode}.field`}
        placeholder={t('placeholderCharityCode')}
        options={getOptions(t)}
        onChange={handleCharityCodeOptions}
        selectedValue={charityCode}
        height={44}
      />
    </>
  )
}

const getOptions = (t: TFunction): GenericSelectItem[] => [
  { label: t('CharityCode919Name'), value: '919' },
  { label: t('CharityCode25885Name'), value: '25885' },
  { label: t('CharityCode333Name'), value: '333' },
  { label: t('CharityCode885521Name'), value: '885521' },
]
