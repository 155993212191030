import React, { useEffect } from 'react'
import css from './auth-callback-container.less'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavigationConstants } from '@dominos/navigation'
import {
  useCurrentOrderDetails,
  useForgeRock,
  useProfileCompletionPopup,
  useReport,
  useSessionStorage,
  useSetLoggedInUser,
} from '@dominos/hooks-and-hocs'
import { ReportLoginData } from '@dominos/hooks-and-hocs/logging'
import { idTokenUtils } from '@dominos/business/functions/customer/id-token-utils'
import { SplashScreen } from '@dominos/components/splash-screen'

type ReportingStatusType = ReportLoginData['status']

const authenticationSource = 'ForgeRock'

export const AuthCallbackContainer = () => {
  const navigate = useNavigate()
  const { setLoggedInUser } = useSetLoggedInUser()
  const { orderId } = useCurrentOrderDetails()
  const { reportLogin } = useReport()
  const { tokenExchange, logoutForgeRock, getForgeRockTokenFromStorage, forceFetchForgeRockToken } = useForgeRock()
  const { decodeToken, deleteToken } = idTokenUtils()
  const { storedValue: previousPage, clearValue } = useSessionStorage({ key: 'forgerock-callback-path' })
  const location = useLocation()
  const { search } = location
  const { showPopup } = useProfileCompletionPopup()

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(search)
      const [code, state, error, accountDeleted] = ['code', 'state', 'error', 'account_deleted'].map((key) =>
        params.get(key),
      )

      if (error) {
        deleteToken()
      }

      if (accountDeleted) {
        handleDeleteAccountCallback()

        return
      }

      if (!code || !state) {
        navigateToPreviousPage()

        return
      }

      let accessToken = await tokenExchange(code, state)
      if (!accessToken) {
        accessToken = await retryFetchToken()
      }

      if (!accessToken) {
        reportData('fail', {
          customerId: undefined,
          status_reason: 'Failed to get ForgeRock auth token',
          payload: { code, state },
        })
        navigateToPreviousPage()

        return
      }

      const tokenPayload = decodeToken(accessToken)
      if (!tokenPayload) {
        reportData('fail', {
          status_reason: 'Failed to decode ForgeRock auth token',
          customerId: undefined,
          payload: { accessToken },
        })
        deleteToken()
        logoutForgeRock()
        navigateToPreviousPage()

        return
      }

      const loggedInUserSuccess = await setLoggedInUser(accessToken, tokenPayload.customerId)
      reportData(loggedInUserSuccess ? 'success' : 'fail', { customerId: tokenPayload.customerId })

      if (!loggedInUserSuccess) {
        logoutForgeRock()
      }

      navigateToPreviousPage()

      if (loggedInUserSuccess) {
        showPopup()
      }
    }

    handleCallback()
  }, [search])

  // When forgot password journey continues on a new tab it doesn't return valid token
  // In that case we need to fetch token again
  // https://backstage.forgerock.com/support/tickets?id=104732
  const retryFetchToken = async () => (await forceFetchForgeRockToken()) ?? (await getForgeRockTokenFromStorage())

  const navigateToPreviousPage = () => {
    navigate(previousPage ? previousPage : NavigationConstants.home)
    clearValue()
  }

  const handleDeleteAccountCallback = () => {
    deleteToken()
    logoutForgeRock()
    navigate(NavigationConstants.home)
  }

  const reportData = (
    status: ReportingStatusType,
    data: Pick<ReportLoginData, 'customerId' | 'status_reason' | 'payload'>,
  ) => {
    const logPayload: ReportLoginData = {
      status,
      identityProvider: 'ForgeRock',
      authenticationSource,
      customerId: data.customerId,
      url: window.location.href,
      order_id: orderId,
      status_error: data.status_reason,
      payload: data.payload,
    }
    reportLogin(logPayload)
  }

  return (
    <div className={css.header} data-testid='auth-callback-container'>
      <SplashScreen testID='auth-callback-container-loading-splash' />
    </div>
  )
}
