import { isNativeApp } from '@dominos/business/functions'
import { ContainerLayout } from '@dominos/components'
import { ErrorNotifier } from '@dominos/components/error'
import {
  useBasket,
  useBreakpoints,
  useCoupons,
  useCurrentOrderDetails,
  usePaymentsAvailableAtStore,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { BasketScene } from '@dominos/scenes'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import styles from './checkout-container.less'
import { DetailsContainer } from './details-container'
import { PaymentContainer } from './payment-container'
import { PlaceOrderContainer } from './place-order-container'
import { ProcessingContainer } from './processing-container'
import { getBasketId } from '@dominos/hooks-and-hocs'
import { Route, Routes } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CheckoutNavigationConstants {
  export const details = 'details'
  export const payment = 'payment'
  export const processing = 'processing'
  export const processingWithParam = `${processing}/:orderId/:orderPaymentId`
  export const placeOrder = 'place-order'
}

const CheckoutContainer = ({ testID }: CheckoutContainerProps) => {
  const [couponAdded, setCouponAdded] = useState(false)
  const { pathname } = useLocation()
  const { basketHeaderData } = useCurrentOrderDetails()
  const coupons = useCoupons()
  const navigate = useNavigate()
  const { basket: { total = 0, totalDiscount = 0 } = {} } = useBasket() || {}
  const isOrderFree = !!coupons.length && !total && totalDiscount > 0

  const { isMobile } = useBreakpoints()

  // preload for next page
  usePaymentsAvailableAtStore(
    basketHeaderData?.storeNo,
    basketHeaderData?.serviceMethod,
    basketHeaderData?.serviceMethodSubType,
    basketHeaderData?.time ?? undefined,
  )

  const onComplete = async () => {
    let route = NavigationConstants.checkoutPayment
    const orderId = getBasketId(window.location.search)

    if (isNativeApp()) {
      route = isOrderFree
        ? NavigationConstants.nativeAppCheckoutPlaceOrder
        : `${NavigationConstants.nativeAppCheckoutPayment}?basketId=${orderId}${window.location.hash}`
    } else if (isOrderFree) {
      route = NavigationConstants.checkoutPlaceOrder
    }
    await navigate(route)
  }

  const onAddCoupon = () => {
    // only for payment page
    if (pathname.startsWith(NavigationConstants.checkoutPayment)) {
      setCouponAdded(true)
    }
  }

  useEffect(() => {
    const checkFreeOrderCoupon = async () => {
      if (couponAdded && isOrderFree) {
        setCouponAdded(false)
        await onComplete()
      }
    }
    checkFreeOrderCoupon()
  }, [coupons, total, totalDiscount])

  return (
    <ContainerLayout
      testID={`${testID}.menu-data-container`}
      sidebar={
        ![NavigationConstants.checkoutProcessing, NavigationConstants.checkoutPlaceOrder].includes(pathname) && (
          <div className={styles.basketSceneContainer}>
            <BasketScene
              excludeNavigation={true}
              hideOrderButton
              hideEditButton
              hideSwapButton
              onAddCoupon={onAddCoupon}
            />
          </div>
        )
      }
      isMenuHidden
    >
      <div className={styles.titledCardContainer} style={{ marginTop: isMobile ? 6 : 20 }} data-testid={testID}>
        <ErrorNotifier>
          <Routes>
            <Route path='/' element={<Navigate to={CheckoutNavigationConstants.details} replace />} />
            <Route
              path={CheckoutNavigationConstants.details}
              element={<DetailsContainer testID={'details-container'} onComplete={onComplete} />}
            />
            <Route path={CheckoutNavigationConstants.payment} element={<PaymentContainer />} />
            <Route path={CheckoutNavigationConstants.processing} element={<ProcessingContainer />} />
            <Route path={CheckoutNavigationConstants.processingWithParam} element={<ProcessingContainer />} />
            <Route path={CheckoutNavigationConstants.placeOrder} element={<PlaceOrderContainer />} />
          </Routes>
        </ErrorNotifier>
      </div>
    </ContainerLayout>
  )
}

export { CheckoutContainer }
