import { UpsellComponentType, UpsellMedia } from '@dominos/components/product/product-upsell/product-upsell.interface'

export interface ProductUpsellBannerData {
  media: UpsellMedia
  price: string | null | undefined
  code: string
  componentType: UpsellComponentType
  componentName: string
}

export const mockProductUpsellBanner: ProductUpsellBannerData = {
  media: {
    name: {
      value: 'Cheesy Crust - $3.49*',
    },
    description: {
      value: "Haven't tried our gooey mozzarella cheesy crust?",
    },
    popupImage: {
      uri: '/ManagedAssets/AU/offer/15986/AU_15986_en_popup.jpeg?v1246694095',
      altText: '',
    },
    bannerImage: {
      uri: '/ManagedAssets/AU/offer/15952/AU_15952_en_banner.jpeg?v1150083687',
      altText: '',
    },
  },
  code: 'CHEESYCRUST',
  price: undefined,
  componentType: 'base',
  componentName: 'Cheesy Crust',
}
