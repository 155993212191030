import { isNativeApp } from '@dominos/business/functions'
import { ActionButton, OrderDetailsPill, RoundUpForCharityCheckout } from '@dominos/components'
import { DeliveryInstructions } from '@dominos/components/checkout'
import { MyDetailsForm } from '@dominos/components/checkout/checkout-container'
import { renderInlineSingleItemOffer } from '@dominos/components/checkout/common/render-single-item-offer'
import { TermsAndConditionsGeneric } from '@dominos/components/terms-and-conditions'
import {
  GenericCardPopup,
  useCheckoutMyDetails,
  useFeatures,
  useKiosk,
  usePaymentAmount,
  useServiceMethod,
} from '@dominos/hooks-and-hocs'
import classnames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './express-checkout-order-popup.less'

interface ExpressCheckoutOrderPopupProps extends BaseProps {
  onClose: () => void
  donationEnabled: boolean
}

// TODO: Replace ActionButton with adyen payment methods
export const ExpressCheckoutOrderPopup = ({ onClose, testID, donationEnabled }: ExpressCheckoutOrderPopupProps) => {
  const [checkoutOffersEnabled] = useFeatures('CheckoutMyDetailsOffers')
  const serviceMethod = useServiceMethod()
  const [_, setDeliveryInstructions] = useState<string>('')
  const { roundUpAmount } = usePaymentAmount(donationEnabled)
  const { setIsMyDetailsValid, rememberMyDetailsState, handleMyDetailsChange, customerDetails } = useCheckoutMyDetails()
  const { t } = useTranslation(['checkout', 'menu'])
  const { isKioskOrder } = useKiosk()

  return (
    <GenericCardPopup testID='express-checkout-popup' popupTitle='' suppressBackgroundScroll={true} onClose={onClose}>
      <h2 className={css.popupTitle} data-testid={`${testID}.title`}>
        {t('Order Details', { defaultValue: 'Order Details', ns: 'menu' })}
      </h2>
      <hr className={css.hr} />
      <div className={css.wrapper}>
        <MyDetailsForm
          onFormValidationChange={setIsMyDetailsValid}
          initialDetails={customerDetails}
          initialRememberDetails={rememberMyDetailsState}
          onChange={handleMyDetailsChange}
          readOnly={true}
          testID={`${testID}.my-details`}
          hideEmptyErrorContainer={true}
        />
      </div>
      <div className={classnames(css.wrapper, css.orderDetailsPill)}>
        {!isNativeApp() && !isKioskOrder && (
          <OrderDetailsPill testID={testID} disableClick={true} isExpressCheckout={true} />
        )}
      </div>
      {checkoutOffersEnabled && (
        <div className={classnames(css.wrapper, css.inlineOfferItem)}>
          {renderInlineSingleItemOffer(`${testID}.checkout-offers`, true)}
        </div>
      )}
      {donationEnabled && (
        <div className={classnames(css.wrapper, css.roundUpForCharity)}>
          <RoundUpForCharityCheckout testID={`${testID}`} amount={roundUpAmount} />
        </div>
      )}
      {serviceMethod === 'Delivery' && (
        <div className={classnames(css.wrapper, css.deliveryInstructions)}>
          <DeliveryInstructions testID={`${testID}.delivery-instructions`} onChange={setDeliveryInstructions} />
        </div>
      )}
      <div className={classnames(css.wrapper, css.link)}>
        <TermsAndConditionsGeneric
          testID={`${testID}.terms-and-conditions`}
          text={t('ExpressCheckoutT&C')}
          forceBlock
          isHyperlink={false}
        />
      </div>
      <div className={css.wrapper}>
        <ActionButton
          testID={`${testID}.applePay`}
          onPress={() => {}}
          text={'Apple Pay'}
          textStyle={{ color: 'white' }}
          containerStyle={{ backgroundColor: 'black', width: '100%' }}
          textFontWeight={'semibold'}
        />
      </div>
    </GenericCardPopup>
  )
}
