import { rootActions } from '@dominos/business'
import {
  BasketContainer,
  EmptyFooter,
  SimpleNavigationTitle,
  SplashScreen,
  StackNavigationBase,
} from '@dominos/components'
import { useBasket, useCoupons, useCurrentOrderDetails, useMenu, useReport } from '@dominos/hooks-and-hocs'
import { Source } from '@dominos/hooks-and-hocs/logging/report-checkout'
import { getStateFromNavigation } from '@dominos/navigation'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

const BasketScene = (props: BasketSceneProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { reportProductRemoveFromCart, reportVoucherUsage, reportVoucherRemoved } = useReport()
  const appliedCoupon = useCoupons()
  const { menu, loadMenu } = useMenu()

  const { basketHeaderData: orderData } = useCurrentOrderDetails()
  const { error: basketError, pending: basketLoading, validationErrors } = useBasket()
  const voucherSelected = getStateFromNavigation<boolean | undefined>(location, 'voucherEnter')

  const addCoupon = (code: string, source: Source = 'Form') => {
    dispatch(rootActions.addCoupons([code]))
    reportVoucherUsage(code, source)
    props.onAddCoupon && props.onAddCoupon()
  }
  const clearValidationErrors = () => dispatch(rootActions.clearValidationErrors())
  const removeLine = (lineItem: BasketLine | BasketCoupon | Bff.Orders.OrderDetails.Basket.ProductLine) => {
    if (lineItem.type === 'BasketCoupon') {
      dispatch(rootActions.removeCoupon(lineItem))
      reportVoucherRemoved(lineItem.code, 'Form', lineItem.discount)
    } else {
      dispatch(rootActions.removeLineFromBasket(lineItem))
      reportProductRemoveFromCart([lineItem] as BasketLine[], appliedCoupon[0]?.code)
    }
  }

  const didChangeOrderTime = (orderTime: string) => {
    dispatch(rootActions.selectOrderTime({ orderTime }))
  }

  useEffect(() => {
    if (!menu) {
      loadMenu()
    }
  }, [])

  if (!props.excludeNavigation && !menu) {
    return <SplashScreen testID='basket-menu-loading-splash' />
  }

  return (
    <Navigation excludeNavigation={props.excludeNavigation}>
      <BasketContainer
        testID={`basket-container`}
        basketError={basketError}
        addCoupon={addCoupon}
        validationErrors={validationErrors}
        orderData={orderData}
        onChangeTime={didChangeOrderTime}
        clearErrors={clearValidationErrors}
        removeLine={removeLine}
        editLine={props.onEditLine}
        voucherSelected={voucherSelected}
        hideOrderButton={props.hideOrderButton}
        hideEditButton={props.hideEditButton}
        hideSwapButton={props.hideSwapButton}
        readonly={props.readonly}
        loading={basketLoading}
        showSubmitButton={props.showSubmitButton}
        onSubmit={props.onSubmit}
      />
    </Navigation>
  )
}

const Navigation = (navProps: React.PropsWithChildren<{ excludeNavigation?: boolean }>) => {
  if (navProps.excludeNavigation) {
    return <div style={{ paddingTop: 1 }}>{navProps.children}</div>
  }

  return (
    <StackNavigationBase testID={'basket'} header={<SimpleNavigationTitle showBackButton />} footer={<EmptyFooter />}>
      {navProps.children}
    </StackNavigationBase>
  )
}

export { BasketScene }
