import React, { useEffect, useState } from 'react'
import { PaymentMethod } from '../payment-method'
import { PaymentMethodProps } from '../payment-method.interface'
import { analytics, useBasketTotal, UseInitiateOrderProps } from '@dominos/hooks-and-hocs'
import { rootActions } from '@dominos/business'
import { useDispatch, useSelector } from 'react-redux'
import { getAsiaPayReturnUrl } from './get-asiapay-return-url'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'

export const PaymentAsiaPay = ({ orderId, paymentSetting, ...props }: PaymentMethodProps) => {
  const dispatch = useDispatch()
  const { getGoogleID } = analytics()
  const orderPaymentId = useSelector((state: RootReducer) => state.currentOrderDetailsReducer.orderPaymentId)
  const email = useSelector((state: RootReducer) => state.currentOrderDetailsReducer.customerDetailsData.emailAddress)
  const url = getAsiaPayReturnUrl(orderId!, orderPaymentId, false, getGoogleID())
  const basketTotal = useBasketTotal()
  const config = getCountryConfig()

  const [redirectUrl, setRedirectUrl] = useState<string>()
  const [orderRef, setOrderRef] = useState<string>()
  const [currCode, setCurrCode] = useState<string>()
  const [payType, setPayType] = useState<string>()
  const [language, setLanguage] = useState<string>()
  const [payMethod, setPayMethod] = useState<string>()
  const [secureHash, setSecureHash] = useState<string>()
  const formRef = React.createRef<HTMLFormElement>()

  useEffect(() => {
    if (redirectUrl && orderRef && currCode && payType && language && payMethod && secureHash) {
      formRef.current?.submit()
    }
  }, [redirectUrl, orderRef, currCode, payType, language, payMethod, secureHash])

  const onInitiateOrderReady: UseInitiateOrderProps['onReady'] = ({ token, properties }) => {
    dispatch(rootActions.setTransactionToken(token))
    setRedirectUrl(`${properties?.find((pp) => pp.key === 'redirectTo')?.value}`)
    setOrderRef(`${properties?.find((pp) => pp.key === 'referenceId')?.value}`)
    setCurrCode(`${properties?.find((pp) => pp.key === 'currencyCode')?.value}`)
    setPayType(`${properties?.find((pp) => pp.key === 'payType')?.value}`)
    setLanguage(`${properties?.find((pp) => pp.key === 'language')?.value}`)
    setPayMethod(`${properties?.find((pp) => pp.key === 'paymentMethod')?.value}`)
    setSecureHash(`${properties?.find((pp) => pp.key === 'secureHash')?.value}`)
  }

  return ['CreditCard', 'LinePay', 'JkoPay'].includes(paymentSetting.paymentMethod) ? (
    <PaymentMethod
      paymentSetting={paymentSetting}
      methodId={paymentSetting.paymentMethod}
      paymentButtonIncludeTotal={true}
      onInitiateOrderReady={onInitiateOrderReady}
      {...props}
    >
      <form
        method='post'
        action={config.ASIAPAY_REDIRECT_URL}
        data-testid={`asiapay.${paymentSetting.paymentMethod}.auth-form`}
        ref={formRef}
        style={{ display: 'none' }}
      >
        <input type='hidden' name='merchantId' value={paymentSetting.accountId!} />
        <input type='hidden' name='amount' value={basketTotal!.toFixed(2)} />
        <input type='hidden' name='orderRef' value={orderRef} />
        <input type='hidden' name='currCode' value={currCode} />
        <input type='hidden' name='payType' value={payType} />
        <input type='hidden' name='lang' value={language} />
        <input type='hidden' name='payMethod' value={payMethod} />
        <input type='hidden' name='secureHash' value={secureHash} />
        <input type='hidden' name='successUrl' value={`${url}`} />
        <input type='hidden' name='failUrl' value={`${url}`} />
        <input type='hidden' name='cancelUrl' value={`${url}`} />
        {paymentSetting.paymentMethod === 'CreditCard' && (
          <input type='hidden' name='threeDSCustomerEmail' value={`${email}`} />
        )}
      </form>
    </PaymentMethod>
  ) : null
}
