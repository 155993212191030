import { isNativeApp } from '@dominos/business/functions/native-app'
import { useAlert } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { useTranslation } from 'react-i18next'

export interface GMOFormPostData {
  ShopID?: string
  OrderID: string
  Status?: string
  PayInfoNo?: string
  PayMethod?: string
  TranDate?: string
  ErrCode: string
  ErrInfo?: string
}

export interface GMOFormQueryParams {
  redirect?: string
  handler?: string
  ga_redirect_id?: string
}

export const GMOFormPostHandler = (body: GMOFormPostData, query: GMOFormQueryParams = {}) => {
  const { showAlert } = useAlert()
  const { t } = useTranslation('checkout')
  const resolve = () => {
    const redirectUrl = new URL(
      isNativeApp() ? NavigationConstants.nativeAppCheckoutProcessing : NavigationConstants.checkoutProcessing,
      window.location.origin,
    )

    if (query.ga_redirect_id) {
      redirectUrl.searchParams.set('ga_redirect_id', query.ga_redirect_id)
    }

    if (!body.ErrCode && body.Status !== 'PAYCANCEL') {
      if (body.OrderID) {
        redirectUrl.searchParams.set('gmoOrderId', body.OrderID)
      }
    } else {
      redirectUrl.pathname = isNativeApp()
        ? NavigationConstants.nativeAppCheckoutPayment
        : NavigationConstants.checkoutPayment
    }

    if (body.ErrCode) {
      showAlert(t('GMOPaymentFailed'))
    }

    window.location.replace(redirectUrl.href)
  }

  return { resolve }
}
