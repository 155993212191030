import React from 'react'
import { StaticSvgIcon } from '@dominos/res'
import css from './profile-header.less'
import { ProfileHeaderProps } from './profile-header.interface'
import { isNativeApp } from '@dominos/business/functions'

const ICON_SIZE = 24

const ProfileHeader = ({ testID, title, onDismiss }: ProfileHeaderProps) => {
  const hideDismiss = !onDismiss && isNativeApp()

  return (
    <div data-testid={testID} className={css.header}>
      <h2 data-testid={`${testID}.title`} className={css.title}>
        {title}
      </h2>

      <div data-testid={`${testID}.accessory`} className={css.accessory}>
        {!hideDismiss ? (
          <button data-testid={`${testID}.dismiss`} className={css.dismiss} onClick={onDismiss}>
            <StaticSvgIcon name={'cross'} width={ICON_SIZE} height={ICON_SIZE} isUnstyled={true} fill={'#ffffff'} />
          </button>
        ) : null}
      </div>
    </div>
  )
}

export { ProfileHeader }
