import { MarketingPreferenceTypes } from './customer-profile-interfaces'

const DEFAULT_MARKETING_FREQUENCY = 1 // twice per week, see #64240
const DEFAULT_VOUCHER_NOTIFICATION_FREQUENCY = 0 // once per week

interface CustomerProfileQueryTypes {
  id: string
  email: string
  name: string
  alternateName?: string
  phoneNumber: string
  orderId: string
  loyaltyEnrolled: boolean
  subscriptions: Record<string, boolean | undefined>
}

export const completeCustomerProfileQueryBuilder = ({
  id,
  email,
  name,
  alternateName,
  phoneNumber,
  orderId,
  loyaltyEnrolled,
  subscriptions,
}: CustomerProfileQueryTypes): Bff.Customers.UpsertCustomer => {
  let variables: Bff.Customers.UpsertCustomer = {
    id,
    email,
    name,
    alternateName,
    phoneNumber,
    loyalty: { isEnrolled: loyaltyEnrolled, redeemPointsOnOrderId: orderId },
  }

  if (subscriptions.marketing || subscriptions.vouchers || subscriptions.loyalty) {
    const marketingPreferences: MarketingPreferenceTypes = {
      emailSubscriptions: [],
    }

    if (subscriptions.loyalty) {
      marketingPreferences.emailSubscriptions!.push({
        edmType: 'LoyaltyNotification',
      })
    }

    if (subscriptions.marketing) {
      marketingPreferences.emailSubscriptions!.push({
        edmType: 'SpecialOffers',
        frequency: DEFAULT_MARKETING_FREQUENCY,
      })
      marketingPreferences.smsSubscriptions = [{ smsSubscriptionType: 'smssubscription' }]
    }

    if (subscriptions.vouchers) {
      marketingPreferences.emailSubscriptions!.push({
        edmType: 'VoucherNotification',
        frequency: DEFAULT_VOUCHER_NOTIFICATION_FREQUENCY,
      })
    }

    variables = { ...variables, ...marketingPreferences }
  }

  return variables
}
