import React, { useState } from 'react'
import { FormValue } from './details-container.interface'
import { rootActions } from '@dominos/business'
import { ServiceMethodAdditionalDetailsProps } from '@dominos/components'
import { useDispatch } from 'react-redux'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs'

const PickUpDetailsForm = ({
  testID,
  serviceMethodSubType,
  renderInlineSingleItemOffer,
  renderServiceMethodType,
  renderTermsAndConditions,
  renderOrderButton,
}: ServiceMethodAdditionalDetailsProps) => {
  const dispatch = useDispatch()
  const { basketHeaderData: currentOrderData } = useCurrentOrderDetails()
  const [carDetails, setCarDetails] = useState<{ [key: string]: FormValue }>({
    carMakeAndModel: currentOrderData?.carMakeAndModel,
    carColour: currentOrderData?.carColour,
  })

  const [isCarDetailsValid, setIsCarDetailsValid] = useState<boolean>(true)

  const handleCarDetailsChange = (field: string | null, value: FormValue) => {
    setCarDetails((prev) => ({ ...prev, [field!]: value }))
  }

  const placeOrderHook = () => {
    if (serviceMethodSubType === 'CarParkDelivery') {
      dispatch(rootActions.updateCarDetails(carDetails))
    }

    return {}
  }

  return (
    <React.Fragment>
      {renderInlineSingleItemOffer(`${testID}.my-details-offers`)}
      {renderServiceMethodType({
        ...(serviceMethodSubType === 'CarParkDelivery'
          ? {
              initialValue: carDetails,
              onFormValidationChange: setIsCarDetailsValid,
              onChange: handleCarDetailsChange,
            }
          : {}),
      })}
      {renderTermsAndConditions()}
      {renderOrderButton({
        placeOrderHook,
        isAdditionalDetailsValid: isCarDetailsValid,
      })}
    </React.Fragment>
  )
}

export default PickUpDetailsForm
