export const toppingUpsellIngredients: IngredientItemProps[] = [
  {
    allowAdd: true,
    allowRemove: true,
    code: 'BURGER',
    maximumReached: false,
    minimumReached: true,
    quantity: 0,
    thumb: 'https://order.dominos.com.au/ManagedAssets/AU/component/BURGER/AU_BURGER_en_MenuImage_13582.jpg?v402903901',
    title: 'Burger Sauce',
    value: 2,
  },
  {
    allowAdd: true,
    allowRemove: true,
    code: 'BURPIEC',
    maximumReached: false,
    minimumReached: true,
    quantity: 0,
    thumb:
      'https://order.dominos.com.au/ManagedAssets/AU/component/BURPIEC/AU_BURPIEC_en_MenuImage_13582.jpg?v-456990594',
    title: 'Burger Pieces',
    value: 3,
  },
  {
    allowAdd: true,
    allowRemove: true,
    code: 'CHBURGR',
    maximumReached: false,
    minimumReached: false,
    quantity: 1,
    thumb:
      'https://order.dominos.com.au/ManagedAssets/AU/component/CHBURGR/AU_CHBURGR_en_MenuImage_13582.jpg?v1144610381',
    title: 'Burger Cheese',
    value: 3,
  },
]
