import { ErrorDefinitions } from '@dominos/interfaces'
import { navigate, NavigationConstants } from '@dominos/navigation'
import { ErrorDefinition } from '@dominos/components'

const trackerErrors: ErrorDefinitions = {
  UNABLE_TO_RESOLVE: {
    translation: {
      key: 'MalformedOrderId',
      options: {
        defaultValue: 'The order ID provided is incomplete or incorrect',
      },
    },
    display: 'alert',
    callback: () => navigate(NavigationConstants.home),
  },
  BAD_USER_INPUT: {
    translation: {
      key: 'OrderNotFound',
      options: {
        defaultValue: 'There is no order associated with this Order ID',
      },
    },
    display: 'alert',
    callback: () => navigate(NavigationConstants.home),
  },
  INTERNAL_SERVER_ERROR: {
    translation: {
      key: 'Order Recoverable Error Message',
      options: {
        defaultValue:
          'Something went wrong when we tried to fetch your order. Please try again in a moment. If the problem persists, please contact us.',
      },
    },
    display: 'alert',
    callback: () => navigate(NavigationConstants.home),
  },
}

const orderCancelledError: ErrorDefinition = {
  id: 'cancelled',
  icon: 'exclamation',
  translation: {
    message: {
      key: 'OrderCancelled',
      getOptions: () => ({
        defaultValue: `Thank you for ordering Domino's, unfortunately we could not connect you to your pizza tracking. Please check your inbox for an order confirmation email with an ETA, or contact your store for any questions. (ER7)`,
      }),
    },
  },
  displayType: 'closable-popup',
  testID: `tracker.ordercancelled.popup`,
}

const orderUnrecoverableError: ErrorDefinition = {
  id: 'unrecoverable',
  icon: 'exclamation',
  translation: {
    message: {
      key: 'Order Unrecoverable Error Message',
      getOptions: () => ({
        defaultValue: `Sorry, something's gone wrong. Please call us to complete your order.`,
      }),
    },
  },
  displayType: 'closable-popup',
  testID: `tracker.unrecoverable.popup`,
}

export { trackerErrors, orderCancelledError, orderUnrecoverableError }
