import React, { createContext, useContext, useState } from 'react'
import { ProfileCompletionModal } from '@dominos/components'
import { useCustomer, useFeatures, useLogout } from '@dominos/hooks-and-hocs'

export const ProfileCompletionPopupHandler = ({ children }: PropsWithChildren) => {
  const [show, setShow] = useState(false)
  const [customerProfileCompletionScreen] = useFeatures('CustomerProfileCompletionScreen')
  const { logout } = useLogout()
  const { customer } = useCustomer()

  const shouldShowPopup = customerProfileCompletionScreen && !isCompletedProfile(customer)

  const dismissPopup = () => {
    setShow(false)
  }
  const hidePopup = () => {
    dismissPopup()
    logout()
  }
  const showPopup = () => {
    setShow(shouldShowPopup)
  }

  return (
    <ProfileCompletionPopupContext.Provider
      value={{
        hidePopup,
        showPopup,
        dismissPopup,
        isVisible: show,
      }}
    >
      {children}
      {shouldShowPopup && show && (
        <ProfileCompletionModal
          testID='profileCompletionModal'
          onSubmit={dismissPopup}
          onClose={hidePopup}
          customer={customer as Bff.Customers.Customer}
        />
      )}
    </ProfileCompletionPopupContext.Provider>
  )
}

export const ProfileCompletionPopupContext = createContext<
  | undefined
  | {
      hidePopup: () => void
      showPopup: () => void
      dismissPopup: () => void
      isVisible: boolean
    }
>(undefined)

export const useProfileCompletionPopup = () => {
  const context = useContext(ProfileCompletionPopupContext)

  if (!context) {
    throw new Error('useProfileCompletionPopup must be used within a ProfileCompletionPopupContext')
  }

  return context
}

const isCompletedProfile = (customer: Bff.Customers.Customer | null | undefined) =>
  customer?.email && customer?.name && customer?.phoneNumber
