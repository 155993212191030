import { orderDetailTerminalPaymentQuery } from '@dominos/business/queries'
import { useEffect } from 'react'
import { ApolloError, useApolloClient } from '@apollo/client'
import { NavigationConstants } from '@dominos/navigation'
import { useNavigate } from 'react-router-dom'
import { OrderResponse } from 'packages/interfaces'
import { isNativeApp } from '@dominos/business/functions'
import { ErrorScope, useErrorContext } from '@dominos/components/error'
import { paymentErrors, usePaymentErrorHandlers } from '@dominos/components/checkout'
import { GraphQLError } from 'graphql'
import { rootActions } from '@dominos/business/root.actions'
import { useDispatch } from 'react-redux'
import { Bff as BffErrors } from 'bff-errors'

const pollDelaySeconds = 2
const maxPollSeconds = 150 // this is the maximum time the terminal will wait for payment
const maxPollCount = maxPollSeconds / pollDelaySeconds

export const usePollTerminalPayment = async ({
  pollTerminalStatus,
  setPollTerminalStatus,
  pollCount,
  setPollCount,
  currentOrder,
  hideAllOverlays,
}: Props) => {
  const client = useApolloClient()
  const { notifyError } = useErrorContext()
  const handlers = usePaymentErrorHandlers()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const query = async () =>
    await client.query<OrderResponse>({
      query: orderDetailTerminalPaymentQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        id: currentOrder.orderId,
      },
    })

  useEffect(() => {
    if (pollTerminalStatus) {
      dispatch(rootActions.setIsInitiatingOrder(true))

      const handleSuccessfulPayment = () => {
        setPollTerminalStatus(false)
        dispatch(rootActions.setIsInitiatingOrder(false))
        navigate(
          isNativeApp() ? NavigationConstants.nativeAppCheckoutProcessing : NavigationConstants.checkoutProcessing,
        )
      }

      const handleFailedPayment = () => {
        setPollTerminalStatus(false)
        dispatch(rootActions.setIsInitiatingOrder(false))
        notifyError({ error: createError(), handlers, definitions: paymentErrors, scope: ErrorScope.Payment })
        hideAllOverlays()
      }

      if (pollCount < maxPollCount) {
        const timer = setTimeout(async () => {
          const response = await query()

          if (response?.data?.order) {
            if (
              isTerminalPaymentSuccessful(response.data.order.terminalPaymentInfo) ||
              isOrderPlaced(response.data.order.status)
            ) {
              handleSuccessfulPayment()
            } else if (
              isTerminalPaymentFailure(response.data.order.terminalPaymentInfo) ||
              isOrderFailed(response.data.order.status)
            ) {
              handleFailedPayment()
            }
          }

          setPollCount(pollCount + 1)
        }, pollDelaySeconds * 1000)

        return () => clearTimeout(timer)
      }

      // payment has timed out
      handleFailedPayment()
    }
  }, [pollTerminalStatus, pollCount])
}

const isOrderPlaced = (orderStatus: Bff.Orders.OrderStatus) =>
  orderStatus &&
  (orderStatus.status === 'Timed' ||
    orderStatus.status === 'Making' ||
    orderStatus.status === 'Remaking' ||
    orderStatus.status === 'Cooking' ||
    orderStatus.status === 'Ready' ||
    orderStatus.status === 'Leaving' ||
    orderStatus.status === 'Complete' ||
    orderStatus.status === 'SentToStore')

const isOrderFailed = (orderStatus: Bff.Orders.OrderStatus) =>
  orderStatus &&
  (orderStatus.status === 'Failed' || orderStatus.status === 'PaymentFailed' || orderStatus.status === 'Cancelled')

const isTerminalPaymentSuccessful = (terminalPaymentInfo: Bff.Orders.TerminalPaymentInfo | undefined): boolean =>
  terminalPaymentInfo?.paymentStatus === 'Success' ?? false

const isTerminalPaymentFailure = (terminalPaymentInfo: Bff.Orders.TerminalPaymentInfo | undefined): boolean =>
  terminalPaymentInfo?.paymentStatus === 'Failure' ?? false

const createError = () =>
  new ApolloError({
    graphQLErrors: [
      {
        message: 'Payment not approved',
        extensions: {
          code: BffErrors.Errors.PAYMENT_NOT_APPROVED,
        },
      } as unknown as GraphQLError,
    ],
  })

interface Props {
  pollTerminalStatus: boolean
  setPollTerminalStatus: React.Dispatch<React.SetStateAction<boolean>>
  pollCount: number
  setPollCount: React.Dispatch<React.SetStateAction<number>>
  currentOrder: { orderId: string }
  hideAllOverlays: () => void
}
